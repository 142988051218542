import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../hocs/PrivateRoute';

import GlobalHeader from '../../components/global-header/global-header';

import CustomerFinance from './components/customer-finance/finance';
import FinanceForm from './components/finance-form/finance-form';
// import StockDashboard from './components/stock-dashboard/stock-dashboard';

import MonthWiseRetail from './../common/resources/monthwise-retail/monthwise-retail';

import CustomerReceipts from '../common/components/customer-receipts/customer-receipts';
import AccountsOverview from '../common/resources/accounts-overview/accounts-overview';
import CustomerReceiptDetail from '../common/components/customer-receipts/customer-receipts-detail';
import CustomerReceiptAdd from '../common/components/customer-receipts/customer-receipts-add';

import BillDetails from '../common/resources/finance-payout-tracker/bill-details';
import ModuleRoutesLatest from '../common/resources/modules-routes-latest/modules-routes-latest';


import FinanceTracker from './../common/resources/finance-tracker/finance-tracker';
// import FinancePayoutTracker from './../common/resources/finance-payout-tracker/finance-payout-tracker';
import ChangePassword from './../common/components/change-password/change-password';
import AddCollection from './../common/components/collections/collections-add';

import GenericList from '../common/resources/generic/generic-list/generic-list';

import StockDashboard from '../common/components/stock-dashboard/stock-dashboard';
import PreferenceDetail from '../common/components/preference-detail/preference-detail';

import ActualStock from '../common/components/actual-stock/actual-stock';
import BookingDetail from '../common/components/booking-detail/booking-detail';

// Pending List
import Collection from './../common/components/sales-collections/collections/collections';
import Report from './../common/components/sales-collections/report/Report';
// Pending List Ends

import TaskRoutes from './../common/resources/task-routes/task-routes';

import { financeSteps, requestColumns } from './finance-config';

import { requestSchema, bookingSchema, payoutSchema } from './../global-config';

import RequestOverview from './../common/resources/request-overview/request-overview';

import GenericRequest from './../common/resources/generic-request/generic-request';

import RequestDetail from './../common/components/request-detail/request-detail';

import './finance-landing.scss';

import { GlobalContext } from '../../Store';

import TaskOverview from './../common/resources/task-overview/task-overview';

import NoticeBoard from './../common/components/notice-board/notice-board';

import Profile from './../common/resources/profile/profile';

import FinancePayoutTracker from './../common/resources/finance-payout-tracker/finance-payout-tracker';
import { FinanceBanks } from '../../models';

export default function FinanceLanding(props) {
    const { user } = useContext(GlobalContext);

    useEffect(() => {}, []);

    const queries = [
        {
            field: 'finance.process',
            value: 'incomplete',
        },
    ];

    const modules = [
        // {
        //     caption: "Customers",
        //     path: `/${user.locations[0]}/customers`,
        // },

        {
            caption: 'Finance Tracker',
            path: '/finance-tracker',
        },
        {
            caption: 'Account Overview',
            path: '/accounts-overview',
        },

        // {
        //     caption: 'Finance Payout',
        //     path: '/payouts'

        // },

        {
            caption: 'Allocations',
            path: '/admin-stock',
        },

        {
            caption: 'Receipts',
            submenus: [
                {
                    path: '/customer-receipts',
                    caption: 'Customer Receipts',
                },
            ],
        },

        {
            caption: 'Monthwise Retail',
            path: '/monthwise-retails',
        },

        // {
        //     caption: 'Finance Payout',
        //     path: '/payouts',
        // },
        {
            caption: 'Finance Banks',
            path: '/finance_banks',
        },

        {
            caption: 'Tasks',
            submenus: [
                {
                    path: '/pending-list',
                    caption: 'Unidentified Receipts',
                },
                {
                    caption: 'Finance Process',
                    submenus: financeSteps.filter((step) => !step.isForm),
                },
            ],
        },

        {
            caption: 'Requests',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/request-overview`,
                                caption: 'Request Overview',
                            },

                            {
                                path: `/${location}/all-requests`,
                                caption: 'All Requests',
                            },

                            {
                                path: `/${location}/pending-requests`,
                                caption: 'Pending Requests',
                            },

                            {
                                path: `/${location}/billing-requests`,
                                caption: 'Pending for Delivery',
                            },

                            {
                                path: `/${location}/delivered-requests`,
                                caption: 'Delivered Requests',
                            },
                        ],
                    };
                }),
            ],
        },
        // {
        //   caption: "Bookings",
        //   submenus: [{
        //     path: '/my-bookings',
        //     caption: 'My Bookings'
        //   }]
        // },

        {
            caption: 'Bookings',
            submenus: [
                ...user.locations.map((location) => {
                    return {
                        caption: location,
                        submenus: [
                            {
                                path: `/${location}/pending-bookings`,
                                caption: 'Pending Bookings',
                            },

                            {
                                path: `/${location}/billing-done`,
                                caption: 'Pending for Delivery',
                            },

                            {
                                path: `/${location}/delivery-done`,
                                caption: 'Delivered Bookings',
                            },
                        ],
                    };
                }),
            ],
        },

        {
            caption: 'Payouts',
            submenus: [
                {
                    caption: 'From Finance',
                    submenus: [
                        ...user.locations.map((location) => {
                            return {
                                caption: location,
                                path: `/${location}/finance-payout`,
                            };
                        }),
                    ],
                },
            ],
        },

        {
            caption: 'DMS Stock',
            path: '/actual-stock',
        },

        {
            caption: 'Change Password',
            path: '/change-password',
        },
    ];

    return (
        <section className="landing">
            <GlobalHeader modules={modules} user={user} history={props.history}>
                <Switch>
                    <Route
                        exact
                        path={'/'}
                        component={() => (
                            <>
                                <NoticeBoard />

                                <TaskOverview steps={financeSteps} />
                            </>
                        )}
                    ></Route>
                    <Route excat path={'/change-password'} component={ChangePassword}></Route>
                    <Route path="/:city/customers" component={() => <GenericRequest columns={requestColumns} queries={queries} />} />

                    <PrivateRoute exact path="/requests/:id" component={RequestDetail} userRole={'finance'} />
                    <Route path={'/finance_banks'} render={(routeParams) => <ModuleRoutesLatest {...routeParams} model={FinanceBanks} />} />

                    <Route
                        exact
                        path="/accounts-overview"
                        userRole={'admin'}
                        render={(routeParams) => {
                            return <AccountsOverview department={['Department Handling Sales']} {...routeParams} />;
                        }}
                    />
                    <Route
                        exact
                        path={`/:city/all-requests`}
                        component={(...routeParams) => <GenericList schema={requestSchema} queries={[]} {...routeParams} />}
                    />

                    <Route exact path={`/:city/request-overview`} component={RequestOverview} />

                    {/* Finance Payout */}
                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/finance-payout`}
                        component={(...routeParams) => <GenericList schema={payoutSchema} queries={[]} {...routeParams} />}
                    />

                    {/* Finance Payout Ends */}

                    {/* finance tracker */}
                    <Route exact path={`/finance-tracker`} component={(...routeParams) => <FinanceTracker {...routeParams} />} />
                    {/* finance tracker Ends */}

                    {/* <Route path="/requests/:id" component={({ ...routeParams }) => <FinanceForm step={{ fields: [] }} {...routeParams} columns={requestColumns} queries={queries} />} /> */}

                    {/* Maintaining listing page for different status */}

                    {/* <Route path="/sm-stock" component={StockDashboard} userRole={'sm'} /> */}
                    {/* <PrivateRoute exact path="/finance/:city/:id" component={FinanceForm} userRole={'sm'} /> */}

                    {/* Customer Receipts Module */}
                    {/* <PrivateRoute exact path="/customer-receipts" component={CustomerReceipts} userRole={'finance'} />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'finance'} />
                    <PrivateRoute exact path="/customer-receipts/add" component={CustomerReceiptAdd} userRole={'finance'} /> */}
                    {/* Customer Receipts Module Ends */}

                    {/* Unidentified List */}
                    <Route path={'/pending-list'} component={Collection}></Route>

                    <Route exact path={'/monthwise-retails'} component={MonthWiseRetail}></Route>

                    {/* Customer Receipts Module */}
                    <Route
                        exact
                        path="/customer-receipts"
                        render={(routeParams) => {
                            return <CustomerReceipts department={['Department Handling Sales']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />
                    <PrivateRoute exact path="/:city/customer-receipts/:id" component={CustomerReceiptDetail} userRole={'finance'} />
                    <Route
                        exact
                        path="/customer-receipts/add"
                        render={(routeParams) => {
                            return <AddCollection department={['Department Handling Sales']} {...routeParams} />;
                        }}
                        userRole={'admin'}
                    />

                    {/* <PrivateRoute exact path="/customer-receipts/add" component={CustomerReceiptAdd} userRole={'finance'} /> */}
                    {/* Customer Receipts Module Ends */}
                    <PrivateRoute exact path="/:city/report/:id" component={Report} userRole={'sm'} />

                    <Route exact path={`/payouts`} component={({ ...routeParams }) => <FinancePayoutTracker {...routeParams} />} />
                    {/* Unidentified List Ends */}

                    <Route path="/admin-stock" component={StockDashboard} userRole={'finance'} />
                    <PrivateRoute exact path="/:city/preference" component={PreferenceDetail} userRole={'finance'} />

                    <PrivateRoute exact path="/actual-stock" component={ActualStock} userRole={'finance'} />
                    <PrivateRoute exact path="/bookings/:id" component={BookingDetail} userRole={'finance'} />

                    <Route exact path="/bill/:id" component={BillDetails} userRole={'admin'} />
                    {/* Requests according to Queries */}

                    {/* finance payout tracker */}
                    {/* <Route
                        exact
                        path={`/payouts`}
                        component={(...routeParams) => (
                            <FinancePayoutTracker {...routeParams} />
                        )}
                    /> */}
                    {/* finance payout tracker Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/pending-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.pendingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Pending Requests */}
                    <Route
                        exact
                        path={`/:city/billing-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.billingRequests} {...routeParams} />
                        )}
                    />
                    {/* Pending Requests Ends */}

                    {/* Delivered Requests */}
                    <Route
                        exact
                        path={`/:city/delivered-requests`}
                        component={(...routeParams) => (
                            <GenericList schema={requestSchema} queries={requestSchema.deliveryRequests} {...routeParams} />
                        )}
                    />
                    {/* Delivered Requests Ends */}

                    {/* Requests according to Queries Done */}

                    {/* Pending Bookings */}
                    <Route
                        exact
                        path={`/:city/pending-bookings`}
                        component={(...routeParams) => (
                            <GenericList schema={bookingSchema} queries={bookingSchema.pendingBookings} {...routeParams} />
                        )}
                    />
                    {/* Pending Bookings Ends */}

                    {/* Billed Bookings */}
                    <Route
                        exact
                        path={`/:city/billing-done`}
                        component={(...routeParams) => <GenericList queries={bookingSchema.billingQueries} schema={bookingSchema} {...routeParams} />}
                    />
                    {/* Billed Booking Ends */}

                    {/* Delivery Bookings */}
                    <Route
                        exact
                        path={`/:city/delivery-done`}
                        component={(...routeParams) => (
                            <GenericList queries={bookingSchema.deliveryRequests} schema={bookingSchema} {...routeParams} />
                        )}
                    />
                    {/* Delivery Bookings Ends */}

                    <Route exact path={'/profile'} component={Profile}></Route>

                    <TaskRoutes steps={financeSteps} list={CustomerFinance} form={FinanceForm} />
                </Switch>
            </GlobalHeader>
        </section>
    );
}
