import React, { useEffect, useState, Fragment, useContext, useRef } from 'react';

import { Radio, Button, Form, Input, message, Typography, InputNumber, Alert, Checkbox, Select, DatePicker, Modal } from 'antd';

import { Tag, Row, Badge, Image } from 'antd';

import AsyncSelect from 'react-select/async';


import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import { useReactToPrint } from 'react-to-print';

import FirebaseUtils from '../../../../utils/firebase.utils';

import ApiUtils from './../../../../utils/api.utils';

import FileUpload from '../../../../components/file-upload/file-upload';

import ConfirmModal from './../../../../components/confirm-modal/confirm-modal';

import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';

import { CheckCircleOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';

import { Departments, Receipts, Users, BankCredits, Settings, CustomerEntries, DocumentLogs, Bookings, Branches } from '../../../../models';

import moment from 'moment';

import './customer-receipts-detail.scss';

import CommentBlock from '../comment-block/comment-block';

const { Title, Text } = Typography;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

var logo = require('./../../../../assets/images/kia-logo.png');

const { TextArea } = Input;

const { Option } = Select;

const otherMode = [
    {
        id: 0,
        name: 'Kodak Mahindra Bank',
    },
    {
        id: 1,
        name: 'Other',
    },
];

let onlineOptions = ['Net Banking'];

export default function CustomerReceiptDetail({ history, match }) {
    const { user } = useContext(GlobalContext);

    const [record, setRecord] = useState({ created_by: {}, attachments: [] });

    const [form] = Form.useForm();

    const [loader, setLoader] = useState(true);

    const [matchingloader, setMatchingloader] = useState(false);

    const [matching, setMatching] = useState([]);

    const [btnloading, setBtnLoading] = useState(false);

    const [edit, setEdit] = useState(false);

    const [manualApprove, setManualApprove] = useState(false);

    const [selectedBank, setSelectedBank] = useState('');

    const [department, setDepartment] = useState([]);

    const [vouchers, setVouchers] = useState({});

    const [visible, setVisible] = useState(false);

    const [access, setAccess] = useState();

    const [selectedDepartment, setSelectedDepartment] = useState();

    const [allDepartments, setAllDepartments] = useState([]);

    const [isResultsVisible, setResultsVisible] = useState(false);

    const [result, setResult] = useState([]);

    const [disabled, setDisabled] = useState(true);

    const [branch, setBranch] = useState();




    //  Revert receipt access entry for accounts team
    let revertReceiptAccess = Users.hasPermission(user, 'revert_receipt');

    // Receipt Reassign to Another Booking
    let receiptReassignAccess = Users.hasPermission(user, 'receipt_reassign');


    const print = useRef();

    useEffect(() => {
        getAllDepartments();
        loadData();
        loadBranch()
    }, []);



    useEffect(() => {
        loadBranch()
    }, [record]);

     //For branch details 
     const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: record.dealerCode,
            },
        ];

        Branches.get(queries).then((result) => {
            setBranch(result.branches);
        });
    };

    useEffect(() => {
        //  for disable print recipt 
        Settings.getValue('print-receipt').then((result) => {
            var allowed = result.value.indexOf(user.role) !== -1;
            setAccess(allowed);
        });
        return () => { };
    }, []);


    const { params } = match;

    const { city, id } = params;

    function onUpload(attachments) {
        form.setFieldsValue({ attachments: attachments });
    }

    function getAllDepartments() {
        Departments.get().then((result) => {
            setAllDepartments(result.departments);
        });
    }

    async function loadData() {
        await FirebaseUtils.getCollectionRecord(id).then(async (result) => {
            console.log(result);

            setRecord(result);

            await getSelectedDepartment(result.department_id);

            if (result.reconciled_by) {
                await getBankRecord(result);
            } else {
                if (!result.suggestion) {
                    await getMatchingCredit(result);
                }
            }

            if (result.voucher) {
                await setVouchers(result.voucher);
            }

            setLoader(false);
        });
    }


    //Handle search query
    const handleOnInputChange = (e) => {
        setResultsVisible(true);
        setDisabled(true)

        //Trigger getResults() only when characters are given
        if (e.target.value) {
            getResults(e.target.value)
        } else {
            setResultsVisible(false);
        }
    };

    const getResults = (id) => {
        Bookings.getRecord(id).then((result) => {
            setResult(result);
            if(result.bookingId){
                setDisabled(false)
            }
        });
    };
    async function getSelectedDepartment(id) {
        return Departments.getRecord(id).then((result) => {
            setSelectedDepartment(result);
        });
    }

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    async function getBankRecord(record) {
        FirebaseUtils.getBankCreditRecord(record.reconciled_by).then((result) => {
            setMatching([result]);
        });
    }

    async function getMatchingCredit(entry) {
        setMatchingloader(true);

        var queries = [
            {
                field: 'source',
                value: 'bank',
            },
            {
                field: 'credit',
                value: entry.amount.toString(),
            },
            {
                field: 'reconcile_status',
                value: 'pending',
            },
        ];

        return FirebaseUtils.getBankCredits(queries).then((result) => {
            // console.log(result);
            setMatchingloader(false);

            let records = result.filter((record) => {
                if ((entry.utr && entry.utr !== '') || (entry.ref && entry.ref !== '')) {
                    var condition =
                        record['description'].indexOf(entry.ref) !== -1 ||
                        record['description'].indexOf(entry.utr) !== -1 ||
                        record['ref'].indexOf(entry.utr) !== -1 ||
                        record['ref'].indexOf(entry.ref) !== -1;

                    return condition;
                } else {
                    return false;
                }
            });

            console.log(records);

            setMatching(records);
        });
    }

    /**
     *
     * For each change in status we update the log
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        let updation = {
            updated_by: { name: user.name, role: user.role },
            updated_at: moment().format('DD/MM/YYYY HH:mm'),
        };


        if (values.amount) {
            values.amount = parseFloat(values.amount);
        }


        let params = {
            ...updation,
            ...values,
        };

        FirebaseUtils.updateCollection(
            // record.city,
            record.collectionId,
            params
        ).then(() => {
            message.success('Payment Collection Recorded');

            loadData();

            setEdit(false);
        });
    };

    /**
     * Function to verify Matching Entry or Manual Verification
     *
     */

    const onVerify = (values) => {
        var matchingEntry = null;
        console.log('values::', values);
        let remarks = form.getFieldValue('verificationRemarks');

        var isCancel = Object.keys(values).length === 0;
        console.log(Object.keys(values));

        let verificationTime = {
            verified_at: moment().format('DD/MM/YYYY HH:mm'),
            verified_by: { name: user.name, role: user.role },
            verified_date: moment().startOf('day').valueOf(),
            verified_time: moment().valueOf(), // this can be removed if not needed
        };

        /**
         * For Cancellation
         *
         */
        if (isCancel) {
            if (!remarks) {
                message.error('Please enter remarks');
            } else {
                let params = {
                    verificationRemarks: remarks,
                    verified: 'rejected',
                    pending_at: 'none',
                    status: 'rejected',
                    ...verificationTime,
                };

                FirebaseUtils.updateCollection(record.collectionId, params).then(() => {
                    message.success('This collection has been rejected');

                    history.goBack();
                });
            }
        } else {
            let params = {
                verificationRemarks: remarks,
                verified: isCancel ? 'rejected' : 'approved',
                pending_at: 'edp',
                status: 'reviewed',
                ...verificationTime,
            };

            // If manually approved , add
            // few params to it

            if (manualApprove) {
                params = {
                    isManuallyApproved: true,
                    // selectedBank: selectedBank,
                    ...params,
                };
            }

            if (matching.length) {
                matchingEntry = matching[0];

                params = {
                    ...params,
                    reconciled_by: matchingEntry.receiptId,
                    pending_to_reconcile: false,
                    clearing_city: matchingEntry.city,
                    bank: matchingEntry.bank,
                    verified_from: 'reconcile',

                    voucher: {
                        ...matchingEntry.voucher,
                    },
                };
            }

            if (onlineOptions.indexOf(record.type) === -1 && record.type === 'Cheque') {
                params = {
                    ...params,
                    pending_to_reconcile: false,
                };
            }

            // If there are any matching records or manually approve
            // is enabled

            if (matching.length || manualApprove) {
                setLoader(true);

                // For matchign records
                if (!manualApprove) {
                    // For Booking Create a New Head

                    let receiptDepartment = allDepartments.filter((dep) => dep.id === matchingEntry.department_id);

                    matchingEntry.department = receiptDepartment[0];

                    BankCredits.reconcileBankCredit(values, record, matchingEntry, user, () => { }, 'RD:S').then(() => {
                        message.success('Payment Collection Recorded');

                        setLoader(false);

                        history.goBack();
                    });

                    // if (record.account === 'BOOKING') {
                    //     ApiUtils.createHead(record, selectedDepartment)
                    //         .then((newHead) => {
                    //             params.ac_code = newHead.ac_code;

                    //             values.ac_code = {
                    //                 value: newHead.ac_code,
                    //             };

                    //             updateBankCredit(values, matchingEntry).then(() => {
                    //                 updateCollection(params).then(() => {
                    //                     message.success('Payment Collection Recorded');

                    //                     setLoader(false);

                    //                     history.goBack();
                    //                 });
                    //             });
                    //         })
                    //         .catch(() => {
                    //             message.error('Creating new head failed !');
                    //         });
                    // } else {
                    //     // Get the value from the object
                    //     // #noteenable
                    //     params.ac_code = values.ac_code.value;
                    //     values.ac_code = values.ac_code.value;

                    //     updateBankCredit(values, matchingEntry).then(() => {
                    //         updateCollection(params).then(() => {
                    //             message.success('Payment Collection Recorded');

                    //             setLoader(false);

                    //             history.goBack();
                    //         });
                    //     });
                    // }
                } else {
                    // message.info('Please contact admin.');

                    var kotakHead = '1030007';

                    params.mode = kotakHead;

                    if (record.city === 'malappuram') {
                        createManualEntry(params);
                    } else {
                        createManualOtherBranchEntry(params);
                    }
                }
            } else {
                message.error('No matching credits found');
            }
        }
    };

    // const address =
    //     record.city === 'kozhikode'
    //         ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
    //         : record.city === 'kannur'
    //             ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
    //             : { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' };

    /**
     *
     * Manual Verification for Malappuram
     */
    function createManualEntry(params) {
        // For Kotak Cases
        if (record.account) {

            updateCollection(params).then(() => {
                message.success('Payment Collection Recorded');

                setLoader(false);

                history.goBack();
            });
        }
        // ApiUtils.createHead(record, selectedDepartment)
        //     .then((newHead) => {
        //         // Update the voucher record
        //         params.ac_code = newHead.ac_code;

        //         // values.ac_code = newHead.ac_code;
        //         values.ac_code = {
        //             value: newHead.ac_code,
        //         };
        //         // #noteenable

        // Add a voucher in Cs Accounts for the Entry
        // ApiUtils.createManualEntry(params, values, record, selectedDepartment)
        //     .then((result) => {
        //         console.log(result);

        //         // params.voucher_no = result.v_vno;
        //         //#note toenable
        //         params.voucher = {
        //             v_vno: result.v_vno,
        //             v_ids: result.v_ids,
        //         };

        //         // For Kotak We have to create a voucher here

        // })
        // .catch(() => {
        //     setLoader(false);
        // });
        // })
        // .catch(() => {
        //     message.error('Creating new head failed !');
        // });
        // } else {
        //     // Add a voucher in Cs Accounts for the Entry
        //     ApiUtils.createManualEntry(params, values, record, selectedDepartment)
        //         .then((result) => {
        //             console.log(result);

        //             //  params.voucher_no = result.v_vno;
        //             params.voucher = {
        //                 v_vno: result.v_vno,
        //                 v_ids: result.v_ids,
        //             };

        //             // For Kotak We have to create a voucher here

        //             updateCollection(params).then(() => {
        //                 message.success('Payment Collection Recorded');

        //                 setLoader(false);

        //                 history.goBack();
        //             });
        //         })
        //         .catch(() => {
        //             setLoader(false);
        //         });
        // }
    }

    /**
     * For Manual Verification
     *
     * Since , Bank is Malappuram
     * We first check if its for same location
     * If not we have to create other branch record
     *
     */
    function createManualOtherBranchEntry(params) {
        // For Kotak Cases
        if (record.account) {
            updateCollection(params).then(() => {
                message.success('Payment Collection Recorded');

                setLoader(false);

                history.goBack();
            });
        }
        //     ApiUtils.createHead(record, selectedDepartment)
        //         .then((newHead) => {
        //             // Update the voucher record

        //             if (newHead && newHead.ac_code) {
        //                 params.ac_code = newHead.ac_code;
        //             }

        //             // values.ac_code = newHead.ac_code;

        //             // #noteenable
        //             values.ac_code = {
        //                 value: newHead.ac_code,
        //             };

        //             // Add a voucher in Cs Accounts for the Entry
        //             ApiUtils.createKotakEntry(params, values, record, selectedDepartment, allDepartments)
        //                 .then((result) => {
        //                     console.log(result);
        //                     if (result && result.v_vno && result.v_ids) {
        //                         // params.voucher_no = result.v_vno;
        //                         params.voucher = {
        //                             v_vno: result.v_vno,
        //                             v_ids: result.v_ids,
        //                         };
        //                     }

        //                     // For Kotak We have to create a voucher here

        //                     updateCollection(params).then(() => {
        //                         message.success('Payment Collection Recorded');

        //                         setLoader(false);

        //                         history.goBack();
        //                     });
        //                 })
        //                 .catch(() => {
        //                     setLoader(false);
        //                 });
        //         })
        //         .catch(() => {
        //             message.error('Creating new head failed !');
        //         });
        // } else {
        //     // Add a voucher in Cs Accounts for the Entry
        //     ApiUtils.createKotakEntry(params, values, record, selectedDepartment, allDepartments)
        //         .then((result) => {
        //             // console.log(result);

        //             // params.voucher_no = result.v_vno;

        //             // #noteenable
        //             params.voucher = {
        //                 v_vno: result.v_vno,
        //                 v_ids: result.v_ids,
        //             };

        //             // For Kotak We have to create a voucher here

        //             updateCollection(params).then(() => {
        //                 message.success('Payment Collection Recorded');

        //                 setLoader(false);

        //                 history.goBack();
        //             });
        //         })
        //         .catch(() => {
        //             setLoader(false);
        //         });
        // }
    }

    /**
     * Update the collection
     */
    function updateCollection(params) {
        return FirebaseUtils.updateCollection(
            // record.city,
            record.collectionId,
            params
        );
    }

    /**
     * Download Receipt PDF
     */
    function downloadReceipt() {
        setBtnLoading(true);

        var receiptnum = record.collectionId.slice(10);

        var currentdate = moment().format('DD/MM/YYYY hh:mm A');

        var receiptdate = moment(record.created_at, 'DD/MM/YYYY hh:mm a').format('DD/MM/YYYY');

        var receipttime = moment(record.created_at, 'DD/MM/YYYY hh:mm a').format('DD/MM/YYYY hh:mm A');

        var params;

        //Receipt for service_accounts

        if (selectedDepartment.description === 'Department Handling Services') {
            //Set all the params needed for receipt generation
            params = {
                receipt_number: receiptnum.toString(),
                address: 'DKH KIA',
                add1: branch && branch[0].address_line_1,
                add2: branch && branch[0].address_line_2,
                receipt_description: record.type,
                receipt_amount: record.amount.toString(),
                receipt_sub_total: record.amount.toString(),
                receipt_total: record.amount.toString(),
                receipt_name: record.name,
                receipt_phone_number: record.phone.toString(),
                receipt_time: receipttime,
                print_time: currentdate,
                receipt_date: receiptdate,
                remarks: record.remarks
            };
            if (record.variant) {
                params = {
                    ...params,
                    model: record.variant,
                };
            } else if (record.model) {
                //If variant is not available lets go with model
                params = {
                    ...params,
                    model: record.model,
                };
            }

            console.log(params);
            Receipts.loadFunction('generateServicePDF', params)
                .then((res) => {
                    console.log(res.body);
                    var bytearray = Object.keys(res);
                    var arrayelement = Object.values(res);
                    var uint8Array = new Uint8Array(bytearray.length);
                    for (var i = 0; i < uint8Array.length; i++) {
                        uint8Array[i] = arrayelement[i];
                    }
                    console.log(uint8Array);

                    for (var i = 0; i < bytearray; i++) {
                        var ascii = arrayelement.charCodeAt(i);
                        uint8Array[i] = ascii;
                    }
                    var arrBuffer = uint8Array;
                    var newBlob = new Blob([arrBuffer], { type: 'application/pdf' });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob);
                        return;
                    }
                    setBtnLoading(false);

                    res = window.URL.createObjectURL(newBlob);
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.href = res;
                    link.download = 'newfile.pdf';
                    link.click();
                    window.URL.revokeObjectURL(res);
                    link.remove();
                })
                .catch((error) => {
                    console.log('error api response', error);
                    return;
                });
        } else {
            //Receipt for sales
            //Set all the params needed for receipt generation
            params = {
                receipt_number: receiptnum.toString(),
                address: 'DKH KIA',
                add1: branch && branch[0].address_line_1,
                add2: branch && branch[0].address_line_2,
                receipt_description: record.type,
                receipt_amount: record.amount.toString(),
                receipt_sub_total: record.amount.toString(),
                receipt_total: record.amount.toString(),
                receipt_name: record.name,
                receipt_phone_number: record.phone.toString(),
                receipt_kec: record.kec,
                receipt_account: record.account,
                receipt_time: receipttime,
                // model: record.variant,
                print_time: currentdate,
                receipt_date: receiptdate,
                remarks: record.remarks
            };

            if (record.variant) {
                params = {
                    ...params,
                    model: record.variant,
                };
            } else if (record.model) {
                //If variant is not available lets go with model
                params = {
                    ...params,
                    model: record.model,
                };
            }

            console.log(params);
            Receipts.loadFunction('generateReceiptPDF', params)
                .then((res) => {
                    console.log(res.body);
                    var bytearray = Object.keys(res);
                    var arrayelement = Object.values(res);
                    var uint8Array = new Uint8Array(bytearray.length);
                    for (var i = 0; i < uint8Array.length; i++) {
                        uint8Array[i] = arrayelement[i];
                    }
                    console.log(uint8Array);

                    for (var i = 0; i < bytearray; i++) {
                        var ascii = arrayelement.charCodeAt(i);
                        uint8Array[i] = ascii;
                    }
                    var arrBuffer = uint8Array;
                    var newBlob = new Blob([arrBuffer], { type: 'application/pdf' });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob);
                        return;
                    }
                    setBtnLoading(false);

                    res = window.URL.createObjectURL(newBlob);
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.href = res;
                    link.download = 'newfile.pdf';
                    link.click();
                    window.URL.revokeObjectURL(res);
                    link.remove();
                })
                .catch((error) => {
                    console.log('error api response', error);
                    return;
                });
        }
    }


    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
        setDisabled(true)
        setResult([])
        };

    /**
    *
    * Revert receipt entry for accounts team
    *
    * @param {*} role
    */
    async function revertReceipt() {
        try {

            var app = FirebaseUtils.getApp();
            var batch = app.batch();
            var params;
 
            // Update the reconcile status of bank receipt
            if (record.reconciled_by) {

                var param = {
                    reconcile_status: 'pending',
                    pending_at: 'new',
                    reconciled_for: BankCredits.firebase().firestore.FieldValue.delete(),

                };

                // Update the reconcile status of bank receipt
                var docRef = BankCredits.getRecordReference(record.reconciled_by);

                await batch.update(docRef, param);

                params = {
                    pending_to_reconcile: true,
                    pending_at: 'new',
                    verified: 'pending',
                    reconciled_by: CustomerEntries.firebase().firestore.FieldValue.delete(),
                };

            } else {

                params = {
                    pending_to_reconcile: true,
                    pending_at: 'new',
                    verified: 'pending',
                };
            }

            //To update the customer_entries with the params returned from update customer_entries
            var customerRef = CustomerEntries.getRecordReference(id);

            await batch.update(customerRef, params);

            var receipts = {
                ...record,
                id: id
            }
            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs
            await DocumentLogs.recordLog('customer_entries', receipts, params, user, batch, customerRef).then(async () => {
                message.success('Successfully reverted receipt')
                loadData();
            })


        } catch (error) {
            console.error('Error reverting receipt:', error);
            message.error('Failed to revert receipt');
        }
    }

    const showModal = () => {
        setVisible(true);
    };



    /**
    *
    * Receipt Reassign to Another Booking
    *
    * @param {*} bookingNo
    */
    const handleItemSubmit = () => {
        form.validateFields()
            .then(async (values) => {

                var app = FirebaseUtils.getApp();
                var batch = app.batch();

                const match = values.bookingNo.match(/^([A-Za-z]+[0-9]+)([A-Za-z0-9]+)$/);

                const dealerCode = match[1]; // "KL305"
                const bookingNo = match[2]; // "B202308984"

                var params = {
                    phone: result['Contact Number'],
                    old_bookingNo: record.bookingNo,
                    bookingNo: bookingNo,
                    dealerCode: dealerCode,
                    is_reassigned: true
                };

                var customerRef = CustomerEntries.getRecordReference(id);

                await batch.update(customerRef, params)

                var receipts = {
                    ...record,
                    id: id
                }
                await DocumentLogs.recordLog('customer_entries', receipts, params, user, batch, customerRef).then(async () => {
                    message.success(`Successfully Reassigned receipt to this ${result['Booking No']}`)
                    setVisible(false)
                    form.resetFields();
                    setResult([])
                    setDisabled(true)
                    loadData();
                });
            })
            .catch((error) => {
                message.error('Failed to Receipt Reassign');
            })

    };

    /**
     * Update the Bank Credit
     */
    // function updateBankCredit(values, matchingEntry) {

    //     return new Promise((resolve, reject) => {

    //         var params2 = {
    //             // pending_to_reconcile: false,
    //             clearing_entry: true,
    //             reconcile_status: 'completed',
    //             pending_at: 'done',
    //             reconciled_for: record.collectionId,
    //             reconciling_city: record.city,
    //             closing: {
    //                 created_at: moment.tz('Asia/Calcutta').startOf('day').valueOf(),
    //                 created_by: user.name,
    //             },
    //         };

    //         if (matchingEntry) {
    //             // There is a chance that the update is made by another city credit

    //             // In that case we need to make additional entries

    //             // to do the interbranch transactions
    //             if (matchingEntry && matchingEntry.city !== record.city) {
    //                 //
    //                 /**
    //                  * Update the voucher record
    //                  */
    //                 return ApiUtils.verifyOtherBranch(matchingEntry, values, record, 'D').then(() => {
    //                     // Update the reconcile status of bank receipt
    //                     FirebaseUtils.updateBankCreditRecord(
    //                         // matchingEntry.city,
    //                         matchingEntry.receiptId,
    //                         params2
    //                     ).then(() => {
    //                         message.success('The updates have been captured');

    //                         resolve({});
    //                     });
    //                 });
    //             } else {
    //                 // #noteenable
    //                 values.ac_code = values.ac_code.value;

    //                 values.v_othref = 'RD:S' + matchingEntry.id;

    //                 /**
    //                  * Update the voucher record
    //                  */
    //                 return ApiUtils.updateVoucherEntry(matchingEntry, values).then(() => {
    //                     // Update the reconcile status of bank receipt
    //                     FirebaseUtils.updateBankCreditRecord(
    //                         // matchingEntry.city,
    //                         matchingEntry.receiptId,
    //                         params2
    //                     ).then(() => {
    //                         message.success('The updates have been captured');

    //                         resolve({});
    //                     });
    //                 });
    //             }
    //         } else {
    //             reject({});

    //             message.warning('No matching entries');
    //         }
    //     });
    // }

    var status;

    if (record.verified === 'approved') {
        status = 'Approved';
    } else if (record.verified === 'rejected') {
        status = 'Rejected';
    } else {
        status = 'Pending';
    }

    let itemsToRender;

    if (result['Booking No']) {

        itemsToRender = (
            <ul className="results-list">
                <li key={result['Booking No']}>
                    <span>
                        <UserOutlined style={{ paddingRight: '10px' }} />
                        {result['Name of the Customer']}
                    </span>
                </li>
            </ul>
        );
    }


    function onCheckboxChange(e) {
        setManualApprove(e.target.checked);
    }

    /**
     * Load the matching heads
     */
    // function loadHeads(query) {
    //     let branch = selectedDepartment;
    //     return ApiUtils.getHeads(query, branch).then((result) => {
    //         return result.map((item) => {
    //             return {
    //                 value: item.ac_code,
    //                 label: item.ac_desc,
    //             };
    //         });
    //     });
    // }

    return (
        <div className="allocation-form">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="vehicle-card">
                            <Badge color="#f50" text={status} />
                            {record.verified === 'approved' && access ? (
                                <Button loading={btnloading} type="dashed" className="receipt-print" onClick={downloadReceipt}>
                                    Print Receipt
                                </Button>
                            ) : null}

                            {/* Revert receipt entry for accounts team */}
                            {record.verified === 'approved' && revertReceiptAccess ? (
                                <Button type="dashed" className="receipt-print" onClick={revertReceipt}>
                                    Revert Receipt
                                </Button>
                            ) : null}

                            {/* Reassign receipt entry for accounts team */}
                            {record.verified === 'approved' && receiptReassignAccess ? (
                                <Button type="dashed" className="receipt-print" onClick={showModal}>
                                    Receipt Reassign to Another Booking
                                </Button>
                            ) : null}



                            {/* Receipt Reassign to Another Booking Modal */}
                            <Modal
                                visible={visible}
                                title="Reassign Receipt"
                                onOk={handleItemSubmit}
                                onCancel={handleCancel}
                                footer={[
                                    <Button key="submit" type="primary" disabled={disabled} onClick={handleItemSubmit}>
                                        Submit
                                    </Button>,
                                ]}
                            >
                                <Form form={form} {...layout} name="new-record" layout="vertical" initialValues={{}}>
                                    <Form.Item
                                        name="bookingNo"
                                        label="Search by Booking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Booking',
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            autoFocus="on"
                                            prefix={<SearchOutlined />}
                                            onChange={handleOnInputChange}
                                            placeholder={'Type to search'}
                                            className={`search-box ${isResultsVisible ? 'bottom-border-no-radius' : ''}`}
                                        />
                                    </Form.Item>

                                    {itemsToRender ? itemsToRender : <span className="light-text">No Results</span>}

                                </Form>
                            </Modal>
                            {/* Modal Ends */}

                            <>
                                {edit ? (
                                    <div className="document-status">
                                        <Title level={3}>Update Customer Receipt </Title>

                                        <p>Please do the updations done as per the proof submitted.</p>

                                        <Form
                                            {...layout}
                                            form={form}
                                            name="new-record"
                                            onFinish={onSubmit}
                                            layout="vertical"
                                            // validateMessages={validateMessages}
                                            initialValues={{
                                                ...record,
                                            }}
                                        >
                                            <Form.Item
                                                name={'amount'}
                                                label="Amount"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter Amount',
                                                    },
                                                ]}
                                            >
                                                <InputNumber min={0} />
                                            </Form.Item>

                                            <Form.Item
                                                name={'name'}
                                                label="Customer Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter customer name',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                name={'phone'}
                                                label="Phone Number"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter phone',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            {record.type === 'Cash' ? null : (
                                                <Form.Item
                                                    name={'utr'}
                                                    label="UTR Number"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter UTR Number',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            )}

                                            <Form.Item
                                                name={'account'}
                                                label="On account of"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'On Account Of',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group>
                                                    <Radio value="BOOKING">Booking</Radio>
                                                    <Radio value="PAYMENT">Part Payment</Radio>
                                                    <Radio value="FINANCE">Finance Payment</Radio>
                                                    <Radio value="EXCHANGE">Exchange Value</Radio>
                                                    <Radio value="INSURANCE">Insurance</Radio>
                                                    <Radio value="ACCESSORIES">Accessories</Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            {record.type === 'Cash' ? null : (
                                                <Form.Item
                                                    name="attachments"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Share attachments of the receipt',
                                                        },
                                                    ]}
                                                    label="Attach Payment Screenshot"
                                                    valuePropName="fileList"
                                                    extra="File size should not exceed 1 mb"
                                                >
                                                    <FileUpload initial={record.attachments} callback={onUpload} />
                                                </Form.Item>
                                            )}

                                            <Form.Item
                                                name={'remarks'}
                                                label="Remarks"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter remarks',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={4} />
                                            </Form.Item>

                                            <Button
                                                type="secondary"
                                                onClick={() => {
                                                    setEdit(false);
                                                }}
                                                className="submit-button"
                                            >
                                                Cancel
                                            </Button>

                                            <Button type="primary" htmlType="submit" className="submit-button">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                ) : (
                                    <div className="vehicle-card card">
                                        <CollectionInfo collection={record} />

                                        {/* Edit Mode Toggle */}

                                        {record.verified === false || !record.invoice ? (
                                            <Button
                                                size="small"
                                                onClick={() => {
                                                    setEdit(true);
                                                }}
                                                type="secondary"
                                                className="submit-button"
                                            >
                                                Edit
                                            </Button>
                                        ) : null}

                                        {/* Edit Mode Toggle */}

                                        {record.status !== 'invoiced' && record.verified != 'rejected' ? (
                                            <ConfirmModal
                                                caption="REJECT"
                                                callback={(val) => {
                                                    FirebaseUtils.updateListing('customer_entries', id, {
                                                        verified: 'rejected',
                                                        remarks: val.values.remarks,
                                                    }).then(() => {
                                                        message.success('This collection has been rejected');

                                                        history.goBack();
                                                    });
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                )}
                            </>

                            {matchingloader ? (
                                <PlaceHolder type="report" />
                            ) : (
                                <>
                                    {matching.length ? (
                                        <Alert message={`${matching.length} matching record has reflected in bank`} type="success" showIcon />
                                    ) : null}

                                    {/* Matching Records */}
                                    {matching.map((entry) => {
                                        return <BankCredit receipt={entry} />;
                                    })}

                                    {/* Matching Records Ends */}
                                </>
                            )}

                            {/* Invoice */}
                            <Invoice record={record} />
                            {/* Invoice  Ends */}

                            {/* Vouchers */}

                            {record.voucher ? <Vouchers collection={record} record={record.voucher} branch={selectedDepartment} /> : null}

                            {/* Vouchers Ends */}

                            {/* Cashier verification */}
                            {['accounts', 'super', 'admin'].indexOf(user.role) !== -1 && !record.verified ? (
                                <div style={{ marginTop: '10px' }}>
                                    {/* <p>Please verify this receipt</p> */}

                                    {/* Manual Approve */}
                                    {edit ? null : (
                                        <div className="manual-approve">
                                            <Checkbox onChange={onCheckboxChange}>Verify Manually</Checkbox>
                                        </div>
                                    )}

                                    {edit ? null : (
                                        <Form
                                            {...layout}
                                            form={form}
                                            name="new-record"
                                            onFinish={onVerify}
                                            layout="vertical"
                                            // validateMessages={validateMessages}
                                            initialValues={
                                                {
                                                    // transaction_date:new Date()
                                                }
                                            }
                                        >
                                            {manualApprove ? (
                                                <div className="manual-approve" style={{ padding: '16px 0px' }}>
                                                    <Form.Item
                                                        name={'selectedBank'}
                                                        label="Bank"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Select Bank',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder="Select"
                                                            style={{
                                                                width: 200,
                                                                margin: '10px 0px',
                                                            }}
                                                        >
                                                            {otherMode.map((mode) => {
                                                                return <Option value={mode.name}>{mode.name}</Option>;
                                                            })}
                                                        </Select>
                                                    </Form.Item>

                                                    {/* Account Head */}
                                                    {/* {selectedDepartment && selectedDepartment.city && record.account !== 'BOOKING' ? (
                                                        <Form.Item
                                                            name={'ac_code'}
                                                            label="Customer Account Head"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Select Account Head',
                                                                },
                                                            ]}
                                                        >
                                                            <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                                                        </Form.Item>
                                                    ) : null} */}
                                                    {/* Account Head Ends */}

                                                    <Form.Item
                                                        name={'transaction_date'}
                                                        label="Transaction Date"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter Entry Date',
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker />
                                                    </Form.Item>

                                                    <Alert
                                                        style={{
                                                            margin: '10px 0px',
                                                        }}
                                                        message="Manual verification is only allowed for Payments through Kotak Bank. Verifying any other receipts manually can cause error in the customer receipts."
                                                        type="error"
                                                        showIcon
                                                        closable
                                                    />
                                                </div>
                                            ) : null}
                                            {/* Manual Approve Ends*/}

                                            {/* For Matching Entry  */}

                                            {/* {!manualApprove && matching.length ? ( */}
                                            <>
                                                {/* Account Head */}
                                                {/* {selectedDepartment && selectedDepartment.city && record.account !== 'BOOKING' ? (
                                                        <Form.Item
                                                            name={'ac_code'}
                                                            label="Customer Account Head"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Select Account Head',
                                                                },
                                                            ]}
                                                        >
                                                            <AsyncSelect cacheOptions defaultOptions loadOptions={loadHeads} />
                                                        </Form.Item>
                                                    ) : null} */}
                                                {/* Account Head Ends */}
                                            </>
                                            {/* ) : null} */}


                                            {manualApprove ? (
                                                <Form.Item
                                                    name={'verificationRemarks'}
                                                    label="Remarks"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter Remarks',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={4} />
                                                </Form.Item>
                                            ) : null}

                                            {record.city !== selectedDepartment.location ? (
                                                <>
                                                    <Alert
                                                        style={{
                                                            margin: '10px 0px',
                                                        }}
                                                        message={`Your current branch has to be ${record.city} to verify this collection.`}
                                                        type="error"
                                                        showIcon
                                                        closable
                                                    />
                                                </>
                                            ) : null}

                                            {manualApprove ? (
                                                <Button
                                                    disabled={record.city !== selectedDepartment.location}
                                                    style={{ marginRight: '10px' }}
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="submit-button"
                                                >
                                                    APPROVE
                                                </Button>
                                            ) : null}

                                            {manualApprove ? (
                                                <Button
                                                    onClick={() => {
                                                        onVerify(true);
                                                    }}
                                                    type="danger"
                                                    className="submit-button"
                                                >
                                                    DECLINE
                                                </Button>
                                            ) : null}
                                        </Form>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Fragment>
            )}
            {/*Section for printing Receipt */}

            <div className="customer-receipt">
                <WrapperClass ref={print}>
                    <div className="bill-preview" style={{ width: '100%' }}>
                        <Fragment>
                            <div className="page-content">
                                <div className="card">
                                    <div className="bill-header">
                                        <div>
                                            <img className="brand-logo" src={logo} alt="Logo" />
                                        </div>
                                        <div>
                                            <div className="address-block">
                                                <div style={{ fontSize: '12px' }}>DKH Kia ,</div>
                                                <div style={{ fontSize: '10px' }}>{branch && branch[0].address_line_1} </div>
                                                <div style={{ fontSize: '10px' }}>{branch && branch[0].address_line_2}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="page-header">
                                        <strong>RECEIPT</strong>
                                    </div>
                                    <div className="bill-details">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Received From</strong>
                                                    </td>
                                                    <td>: {record.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Phone Number</strong>
                                                    </td>
                                                    <td>: {record.phone}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Date & Time</strong>
                                                    </td>
                                                    <td>: {record.created_at}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>KEC</strong>
                                                    </td>
                                                    <td>:{record.kec}</td>
                                                </tr>

                                                {record.model ? (
                                                    <tr>
                                                        <td>
                                                            <strong>Model</strong>
                                                        </td>
                                                        <td>:{record.model}</td>
                                                    </tr>
                                                ) : null}

                                                {record.variant ? (
                                                    <tr>
                                                        <td>
                                                            <strong>Variant</strong>
                                                        </td>
                                                        <td>:{record.variant}</td>
                                                    </tr>
                                                ) : null}

                                                <tr>
                                                    {record.color ? (
                                                        <>
                                                            <td>
                                                                <strong>Color</strong>
                                                            </td>
                                                            <td>:{record.color}</td>
                                                        </>
                                                    ) : null}
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Collection ID</strong>
                                                    </td>
                                                    <td>:{record.collectionId}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <strong>Payment Collected on Account for</strong>
                                                    </td>
                                                    <td>: {record.account}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <strong>Mode of Payment</strong>
                                                    </td>
                                                    <td>:{record.type}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <strong>Reemarks</strong>
                                                    </td>
                                                    <td>:{record.remarks}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr className="solidline"></hr>
                                    <div className="bill-details">
                                        <strong>Amount Collected</strong>
                                        <p>{record.amount}</p>
                                    </div>

                                    <hr class="solidline"></hr>
                                    <div className="bill-footer">
                                        <div>
                                            <p>Created On: {record.created_at}</p>
                                            <p>Printed On: {moment().format('DD-MM-YYYY')}</p>
                                        </div>
                                        <p>Signature</p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </WrapperClass>
            </div>
            {/*Print Block ends */}
            {record.collectionId ? <CommentBlock id={record.collectionId} /> : null}
        </div>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

function BankCredit({ receipt = {} }) {
    var created_by;
    var created_at;

    if (receipt.rejectRemarks) {
        if (receipt.smReject) {
            created_by = receipt.smReject.created_by;

            created_at = receipt.smReject.created_at;
        } else if (receipt.edpReject) {
            created_by = receipt.edpReject.created_by;

            created_at = receipt.edpReject.created_at;
        }
    }

    return (
        <div className="card">
            <h3 className="title">{receipt['description']}</h3>
            <h2 className={`title ${receipt['debit'] ? 'amount' : 'credit'}`}>{receipt['debit'] || receipt['credit']}</h2>
            <p className="title ">{receipt['ref']}</p>
            <p className="">
                <Tag color="magenta">{receipt.location || receipt.bank}</Tag>

                {receipt.location || receipt.city ? <Badge color="#f50" text={receipt.city || receipt.location} /> : null}
            </p>
            Credited at {receipt['txn_date']}
            <VoucherInfo receipt={receipt} />
            <div>
                {receipt.rejectRemarks ? (
                    <Text type="danger">
                        {receipt.rejectRemarks}, By {created_by} at {created_at}
                    </Text>
                ) : null}
            </div>
            {receipt.edp && receipt.edp.created_at ? (
                <>
                    Created at {receipt.edp.created_at}, By {receipt.edp.created_by}
                </>
            ) : null}
        </div>
    );
}

/**
 * Voucher
 */
function VoucherInfo({ receipt = { voucher: {} } }) {
    return (
        <>
            <Tag color="orange">Voucher :{receipt && receipt.voucher ? receipt.voucher.v_vno : '---'}</Tag>
        </>
    );
}

function CollectionInfo({ collection = { created_by: {}, attachments: [] } }) {
    return (
        <div>
            <div className="detail-element">
                <Row span={12}>
                    <h3>Name :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title">{collection.name}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Amount :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="title amount">{collection.amount}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>KEC:</h3>
                </Row>
                <Row span={12}>
                    <h2 className="">{collection.kec}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Team Leader :</h3>
                </Row>
                <Row span={12}>
                    <h2 className="">{collection.teamLeader}</h2>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>UTR :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.utr}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Card Number :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.cardNo}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Cheque Number :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.chequeNo}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Cheque Date :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.chequeDate}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <span>Attachments</span>
                </Row>

                <>
                    {collection.attachments &&
                        collection.attachments.map((file) => {
                            return (
                                <>
                                    {file.type === 'application/pdf' ? (
                                        <embed src={file.url} width="500" height="375" type="application/pdf" />
                                    ) : (
                                        <Image width={200} src={file.url} />
                                    )}
                                </>
                            );
                        })}
                </>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>On Account Of :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.account}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Remarks :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.remarks}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Phone :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.phone}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Created At :</h3>
                </Row>
                <Row span={12}>
                    <span>{collection.created_at}</span>
                </Row>
            </div>

            <div className="detail-element">
                <Row span={12}>
                    <h3>Created By :</h3>
                </Row>
                <Row span={12}>
                    <span>
                        {collection.created_by.name} - {collection.created_by.role}{' '}
                    </span>
                </Row>
            </div>

            <div>
                {collection.verificationRemarks ? (
                    <Text type="danger">
                        {collection.verificationRemarks}, By {collection.verified_by && collection.verified_by.name && collection.verified_by.name} at{' '}
                        {collection.verified_at}
                    </Text>
                ) : null}
            </div>
        </div>
    );
}

function Invoice({ record }) {
    return (
        <>
            {record && record.invoice && record.invoice ? (
                <div className="vehicle-card card">
                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Invoice Done
                    </Tag>

                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <span>Invoice</span>
                            </Row>
                            <Row span={12}>
                                <ImageWrapper type={Array.isArray(record.invoice) ? 'array' : 'object'} content={record.invoice} />
                            </Row>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

/**
 *
 * Vouchers
 */
function Vouchers({ collection, record = { v_ids: [] }, branch }) {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        getRecord(record.v_vno, branch);
    }, []);

    function getRecord(voucher, branch) {
        ApiUtils.getVouchers(voucher, branch).then((result) => {
            setRecords(result);

            console.log(result);
        });
    }

    return (
        <>
            {records.map((v_id, key) => (
                <VoucherRecord key={key} record={v_id} />
            ))}
        </>
    );
}

/**
 * Voucher Record
 */
function VoucherRecord({ record = {} }) {
    return (
        <div className="card">
            <h3 className="title">
                Voucher {record.v_vno} - {record['v_id']}
            </h3>
            <h2 className={`title ${record['v_trn'] === -1 ? 'amount' : 'credit'}`}>{record['v_amt']}</h2>
            <p className="title ">{record['v_acptr']}</p>
            Credited at {record['v_dt']}
        </div>
    );
}
