import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';

import { Typography, Table, Popover, Modal, message, Button, Radio, InputNumber, Skeleton, Menu, Dropdown } from 'antd';

import { Form, Input, Checkbox, Select } from 'antd';

import { useReactToPrint } from 'react-to-print';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { GlobalContext } from './../../Store';

import Rupee from './../../components/rupee/rupee';

import { ExclamationCircleOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';

import FirebaseUtils from './../../utils/firebase.utils';

import { Departments, Cart, Requests, BillDetails, Bills, Settings, Bookings, Enquirys, Vehicles, Branches, StockPoints, StockTransfers } from './../../models';

import CustomerInfo from './../../components/customer-info/customer-info';

import { Location } from '../../utils/location.utils';

import './bill-preview.scss';

const { confirm } = Modal;

var companyLogo = require('./../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea } = Input;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export const BillPreview = ({ match, callback }) => {
    const params = Location.search();

    const [inTransit, setInTransit] = useState();

    const [booking, setBooking] = useState();

    const [enquiry, setEnquiry] = useState();

    const [loading, setLoading] = useState(false);

    const [request, setRequest] = useState({});

    const [data, setData] = useState([]);

    const [branchId, setBranchId] = useState();

    // Users
    const { user } = useContext(GlobalContext);

    const { defaultBranch } = useContext(GlobalContext);

    let history = useHistory();

    const [isgst, setIsgst] = useState(false);

    const [department, setDepartment] = useState();

    const [state, setState] = useState('Kerala');

    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const [billDiscount, setBillDiscount] = useState(0);

    const print = useRef();

    const [lastNumber, setLastNumber] = useState({});

    const [form] = Form.useForm();

    useEffect(() => {
        user.branch = defaultBranch;
        loadData();
        getInvoiceNumber();
    }, []);

    function loadData() {
        setLoading(true);

        getRequest(params.requestId).then((result) => {
            getCartItems(result);
        });
    }

    /**
     * Get the request of id
     *
     * @param {*} id
     * @returns
     */
    function getRequest(id) {
        return Requests.getRecord(id).then((result) => {
            setRequest(result);
            getEnquiry(result['bookingNo']);
            getBooking(result['bookingNo']);
            getBranch(result);
            return result;
        });
    }

    function getBranch(request) {
        var query = [
            {
                field: 'location',
                value: request.city,
            },
        ];
        Branches.get(query)
            .then((result) => {
                console.log(result);
                setBranchId(result.branches[0].id);
                return result.branches[0].id;
            })
            .then((id) => {
                getDepartment(id);
            });
    }
    console.log(request);

    /**
     * Get the Booking of id
     *
     * @param {*} id
     * @returns
     */
    function getBooking(id) {
        return Bookings.getRecord(id).then((result) => {
            setBooking(result);

            return result;
        });
    }

    /**
     * Get the Enquiry of id
     *
     * @param {*} BookingNo
     * @returns
     */
    function getEnquiry(bookingNo) {
        let query = [
            {
                field: 'Booking No',
                value: bookingNo,
            },
        ];
        return Enquirys.get(query).then((result) => {
            console.log(result);
            setEnquiry(result.enquiries[0]);

            return result;
        });
    }

    console.log(enquiry);

    /**
     * Get the request of id
     *
     * @param {*} id
     * @returns
     */
    function getCartItems(request) {
        var queries = [
            // {
            //     field: 'customerID',
            //     value: request.customer.customerID,
            // },
            {
                field: 'bookingNo',
                value: request.bookingNo,
            },

            {
                field: 'dealerCode',
                value: request.dealerCode,
            },
            {
                field: 'type',
                value: 'basic',
            },
        ];

        console.log(request);

        Cart.getVehicleDetailed(queries, request)
            .then((result) => {
                console.log(result);
                result = result.filter((v) => v.status !== 'removed');

                //check vehicle is in cart
                if (!result.length) {
                    message.error("Can't find vehicle");
                    history.push('/vehicle-billing-requests');
                }

                return result;
            })
            .then((detailed) => {
                Vehicles.getRecord(detailed[0].vinNo).then(async (vehicle) => {
                    //check vehicle is in-transit mode
                    if (vehicle.stock_status === 'in-transit') {
                        let content = 'This vehicle is in in-transit mode can not raise invoice';
                        showWarningModal(content);
                    }

                    //check vehicle is in same dealer
                    if (vehicle.dealerCode !== request.dealerCode) {
                        let content = 'Stock transfer process is not completed!';
                        showWarningModal(content);
                    }

                    //Check if vehicle is present at any stocklocation with same dealer code 
                    if (vehicle.stock_point_id) {
                        var result = await StockPoints.getRecord(vehicle.stock_point_id)
                        if (vehicle.dealerCode != result.dealerCode) {
                            let content = 'Vehicle should be present at a yard within the same dealer code.'
                            showWarningModal(content)

                        }
                    }

                    // Check if any virtual stock transfer is initiated
                    var query = [
                        {
                            field: "vinNo",
                            value: vehicle.id
                        },
                        {
                            field: "sub_mode",
                            value: "virtual"
                        },
                        {
                            field: "active",
                            value: true
                        }
                    ]

                    StockTransfers.get(query).then((result) => {

                        if (result.stock_transfers.length) {

                            // If the active virtual stock transfer status is not done, then billing is not allowed
                            const isDoneStatus = result.stock_transfers.some(obj => obj.status === 'done');

                            if (!isDoneStatus) {
                                let content = 'Virtual stock transfer request is not complete for this vehicle'
                                showWarningModal(content)
                            }
                        }
                    })
                });

                setData(detailed);

                setLoading(false);
            });
    }

    function showWarningModal(content) {
        Modal.warning({
            title: 'Sorry! Can not raise invoice for this vehicle',
            content: content,
            okText: 'Ok',
            onOk() {
                history.goBack();
            },
        });
    }

    /**
     * Close the settlement Method
     */
    const handleGenericSettlement = (values) => {
        confirm({
            title: 'Raise Invoice',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to bill these items, This action is one time',
            onOk() {
                console.log('OK');

                setLoading(true);

                closeFile(values);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    /**
     * Close the file
     */
    function closeFile(values) {
        const hide = message.loading('Creating Bill...', 0);

        let discount;

        if (billDiscount !== 0) {
            discount = {
                billDiscountType: billDiscount.billDiscountType,
                billDiscountValue: billDiscount.billDiscountValue,
            };
        } else {
            discount = null;
        }

        let supplier_id = null;
        if (data[0].category === 'Accessory') {
            supplier_id = user.branch.accessory_supplier_id;
        } else if (data[0].category === 'VAS') {
            supplier_id = user.branch.vas_supplier_id;
        } else if (data[0].category === 'vehicle') {
            supplier_id = user.branch.vehicle_supplier_id;
        }

        let formBody = {
            ...discount,
            selectedRecords: data,
            lastNumber: lastNumber,
            user: user,
            city: request.city,
            customer_name: values.customer_name,
            customer_address: values.customer_adress,
            customer_phone: values.customer_phone,
            customer_email: values.customer_email,
            customer_postal_code: values.customer_postal_code,
            ship_to_name: values.ship_to_name,
            ship_to_address: values.ship_to_address,
            ship_to_phone: values.ship_to_phone,
            ship_to_email: values.ship_to_email,
            ship_to_postal_code: values.ship_to_postal_code,
            department: department[0],
            supplier_id: supplier_id,
            vin_no: request.allocation.vinNo,
            attached_with: 'request',
            branch_id: branchId,
            dealerCode: request.dealerCode,
            requestId: request.id,
            ...values,
        };

        console.log(formBody);

        // Complete Billing at backend
        Bills.loadFunction('createBill', formBody).then((result) => {
            if (result.status === 'success') {
                // Once done , Move file to
                // Requests.completeBilling(request)
                //  .then((res, error) => {
                message.success('Bill has been successfully created');

                setLoading(false);

                hide();

                history.replace(`/bill/${result.bill_id}`);
                // })
                // .catch((e) => {
                //     message.warning(e);

                //     setLoading(false);

                //     hide();
                // });
            } else {
                message.error(result.message);

                setLoading(false);

                hide();
                history.push('/vehicle-billing-requests');
            }
        });
    }

    function getInvoiceNumber() {
        setInvoiceLoading(true);

        getRequest(params.requestId).then((request) => {
            console.log(request);
            FirebaseUtils.getBillOrder(request.city, 'basic', moment().valueOf())
                .then((res) => {
                    let id = res.prefix + res.branch_id + (res.last_number + 1) + res.post_fix;

                    setLastNumber({ id: res.id, lastNumber: id, numberOnly: res.last_number + 1 });

                    setInvoiceLoading(false);
                })
                .catch(() => {
                    message.error('Getting Bill Number breaks, Try again! .');
                });
        });
    }

    // Overriding function for discount
    data.map((item) => {
        item.callback = (discount) => {
            console.log(discount);

            var val = {
                ...item,
                discount,
            };

            console.log(val);

            // Update the cart and reload the calculation
            Cart.update(item.id, discount).then(() => {
                loadData();
            });

            return val;
        };
    });

    const getDepartment = (branchId) => {
        let queries = [
            {
                field: 'branch_id',
                value: branchId,
            },
            {
                field: 'description',
                value: `Department Handling Sales`,
            },
        ];
        return Departments.get(queries).then((result) => {
            setDepartment(result.departments);
        });
    };

    const igst_columns = [
        {
            title: '#',
            width: 20,
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Description',
            dataIndex: 'name',
            width: 220,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div>{text ? text : record['name']}</div>
                        {/* {
                            record.updatedType == 'BILLED (VAS)' && !printTab ?
                                <div style={{ marginLeft: '10px' }}><FileTextOutlined /></div>
                                :
                                null
                        } */}
                    </div>
                );
            },
        },
        // {
        //     title: 'Rate',
        //     dataIndex: 'rate',
        // },
        // {
        //     title: 'Quatity',
        //     dataIndex: 'quantity',
        // },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? '%' + record.discount_value
                                : record.discount_type && record.discount_type === 'amount'
                                    ? '₹' + record.discount_value
                                    : 0}
                        </Popover>
                    </div>
                );
            },
            width: 150,
        },

        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>
                            {((parseFloat(record.quantity) ? parseFloat(record.quantity) : 1) * parseFloat(record.taxable_value)).toFixed(2)}
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'IGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.igst_amount ? record.igst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.igst ? record.igst : 0}%</div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'CESS',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cess_amount ? record.cess_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cess ? record.cess : 0}%</div>
                    </div>
                );
            },
            // width: 100,
        },

        {
            title: 'Net Amount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <div style={{ height: '20px' }}>{text.net_amount}</div>
                    </div>
                );
            },
            width: 200,
        },
        {
            title: 'Actions',
            render: (record) => {
                return (
                    <>
                        <MoreAction callback={record.callback} data={record} />
                    </>
                );
            },
        },
    ];

    const columns = [
        {
            title: '#',
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div style={{ display: 'flex' }}>
                            <div>{text ? text : record['name']}</div>
                            {/* {
                            record.updatedType == 'BILLED (VAS)' && !printTab ?
                                <div style={{ marginLeft: '10px' }}><FileTextOutlined /></div>
                                :
                                null
                        } */}
                        </div>
                        <small>{record.part_number}</small>
                    </Fragment>
                );
            },
        },
        // {
        //     title: 'Rate',
        //     dataIndex: 'rate',
        // },
        // {
        //     title: 'Quatity',
        //     dataIndex: 'quantity',
        // },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? '%' + record.discount_value
                                : record.discount_type && record.discount_type === 'amount'
                                    ? '₹' + record.discount_value
                                    : 0}
                        </Popover>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.taxable_value}</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'SGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.sgst_amount ? record.sgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.sgst ? record.sgst : 0}%</div>
                    </div>
                );
            },
            // width: 100,
        },
        {
            title: 'CGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cgst_amount ? record.cgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cgst ? record.cgst : 0}%</div>
                    </div>
                );
            },
            // width: 100,
        },

        {
            title: 'CESS',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cess_amount ? record.cess_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cess ? record.cess : 0}%</div>
                    </div>
                );
            },
            // width: 100,
        },

        {
            title: 'Net Amount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{text.net_amount}</div>;
            },
            // width: 150,
        },

        {
            title: 'Actions',
            render: (record) => {
                return (
                    <>
                        <MoreAction callback={record.callback} data={record} />
                    </>
                );
            },
        },
    ];

    function calculatedAmount(amt, sgst, cgst) {
        let cgst1 = 0;
        let sgst1 = 0;
        if (cgst) {
            cgst1 = amt * (cgst / 100);
        }
        if (sgst) {
            sgst1 = amt * (sgst / 100);
        }
        let total = amt + parseFloat(cgst1) + parseFloat(sgst1);
        return total;
    }

    const address =
        user.locations[0] == 'kozhikode'
            ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
            : user.locations[0] == 'kannur'
                ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
                : { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' };

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const getDateAndTime = () => {
        let date = new Date();
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    /**
     *
     */
    function onSubmit(values) {
        handleGenericSettlement(values);
    }

    const discountSubmit = (values) => {
        setBillDiscount({ ...values });
    };

    function refresh() {
        loadData();
    }
    return (
        <div className="bill-preview-page">
            <div className="invoice-container">
                <div className="page-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div>
                        <img className="logo-welcome" src={companyLogo} alt="Logo" />
                        <Title level={3}>Invoice # {lastNumber.lastNumber}</Title>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {/* <div style={{ border: '1px dashed #d9d9d9', padding: '4px 15px', marginRight: '10px' }}>Bill No :- {billNumber}</div> */}
                        {/* <Button type="dashed" className="print-button" onClick={handlePrint}>
                            Print Invoice
                        </Button> */}
                    </div>
                </div>

                <div className="bill-header">
                    {request.id && <CustomerInfo request={request} />}

                    <Button size="small" onClick={refresh}>
                        Refresh
                    </Button>
                </div>

                <div>
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <Summary
                            data={data}
                            columns={state !== 'Kerala' ? igst_columns : columns}
                            isInter={state !== 'Kerala' ? true : false}
                            callback={discountSubmit}
                        />
                    )}
                </div>
            </div>
            {request && request.customer && booking && booking.id ? (
                <div className="modal-footer card">
                    <Title level={3}>Create Bill</Title>

                    <p>Enter the below information to continue creating the bill .</p>

                    <Form
                        {...layout}
                        form={form}
                        name="new-record"
                        onFinish={onSubmit}
                        layout="vertical"
                        onFieldsChange={(fields) => {
                            let field = fields[0];

                            if (field) {
                                if (field.name[0] === 'is_gst_customer') {
                                    setIsgst(field.value);
                                }

                                if (field.name[0] === 'customer_state') {
                                    setState(field.value);
                                }
                            } else {
                            }
                        }}
                        // validateMessages={validateMessages}
                        initialValues={{
                            customer_name: request && request.customer && request.customer.customerName,
                            customer_address: request && request.customer && request.customer.place,
                            customer_phone: request && request.customer && request.customer.phone,
                            customer_email: enquiry && enquiry['Customer Email'],
                            ship_to_name: (booking && booking['Ship Name']) || (request && request.customer && request.customer.customerName),
                            ship_to_address: (booking && booking['Ship To Add']) || (request && request.customer && request.customer.place),
                            ship_to_phone: (booking && booking['Ship To phone']) || (request && request.customer && request.customer.phone),
                            ship_to_email: enquiry && enquiry['Customer Email'],
                            ship_to_postal_code:
                                (booking && booking['Ship To Postal']) || (request && request.customer && request.customer.postl_code),
                        }}
                    >
                        <Form.Item
                            name={'customer_name'}
                            label="Customer Name"
                            rules={[
                                {
                                    message: 'Please enter customer name.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Enter Name" />
                        </Form.Item>
                        <Form.Item
                            name={'customer_address'}
                            label="Customer Address"
                            rules={[
                                {
                                    message: 'Please enter customer Address.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Enter Address" />
                        </Form.Item>
                        <Form.Item
                            name={'customer_phone'}
                            label="Customer Phone"
                            rules={[
                                {
                                    message: 'Please enter customer Phone.',
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Enter Phone" />
                        </Form.Item>

                        <Form.Item
                            name={'customer_email'}
                            label="Customer Email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    message: 'Please enter customer Email.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Enter Email" />
                        </Form.Item>
                        <Form.Item
                            name={'customer_postal_code'}
                            label="Customer Postal Code"
                            rules={[
                                {
                                    message: 'Please enter customer postal code.',
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Enter Postal Code" />
                        </Form.Item>
                        <Form.Item
                            name={'ship_to_name'}
                            label="Ship To Name"
                            rules={[
                                {
                                    message: 'Please enter ship to name.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Enter Ship To Name" />
                        </Form.Item>
                        <Form.Item
                            name={'ship_to_address'}
                            label="Ship To Address"
                            rules={[
                                {
                                    message: 'Please enter ship to address.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Enter Ship To Address" />
                        </Form.Item>
                        <Form.Item
                            name={'ship_to_phone'}
                            label="Ship To Phone"
                            rules={[
                                {
                                    message: 'Please enter ship to Phone.',
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Enter Ship To Phone" />
                        </Form.Item>
                        <Form.Item
                            name={'ship_to_email'}
                            label="Ship To Email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    message: 'Please enter ship to Phone.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Enter Ship To Email" />
                        </Form.Item>
                        <Form.Item
                            name={'ship_to_postal_code'}
                            label="Ship To Postal code"
                            rules={[
                                {
                                    message: 'Please enter ship to postal code.',
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} placeholder="Enter Ship To Postal code" />
                        </Form.Item>

                        <Form.Item name="is_gst_customer" valuePropName="checked" wrapperCol={{ span: 16 }}>
                            <Checkbox>GST Customer ?</Checkbox>
                        </Form.Item>

                        {isgst ? (
                            <Form.Item
                                name={'gst_number'}
                                label="GST Number"
                                rules={[
                                    {
                                        message: 'Please enter GSTIN',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        ) : null}

                        <Form.Item name="tcsExempted" valuePropName="checked" wrapperCol={{ span: 16 }}>
                            <Checkbox>TCS Exempted ?</Checkbox>
                        </Form.Item>


                        <Form.Item
                            name={'customer_state'}
                            label="Customer State"
                            rules={[
                                {
                                    message: 'Please select state',
                                    required: true,
                                },
                            ]}
                        >
                            <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                                {['Kerala', 'Pondicherry', 'Tamil Nadu', 'Karnataka', 'Andhra Pradesh', 'Other'].map((model, key) => {
                                    return (
                                        <Option value={model} key={key}>
                                            {model}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={'pan_number'}
                            label="Pan Number"
                            rules={[
                                {
                                    message: 'Please enter pan number.',
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Enter pan number" />
                        </Form.Item>

                        <Form.Item
                            name={'payment_method'}
                            label="Payment&nbsp;Method"
                            rules={[
                                {
                                    message: 'Please select payment method',
                                    required: true,
                                },
                            ]}
                        >
                            <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                                {['Cash', 'Cheque', 'Debit Card', 'Credit Card', 'Net Banking', 'Other'].map((model, key) => {
                                    return (
                                        <Option value={model} key={key}>
                                            {model}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        {invoiceLoading ? (
                            <Button type="primary" loading>
                                Loading
                            </Button>
                        ) : (
                            <Button loading={loading} htmlType="submit" type={'primary'}>
                                Create Bill
                            </Button>
                        )}
                    </Form>
                </div>
            ) : null}
        </div>
    );
};

function Summary({ data, columns, refresh, isInter, callback }) {
    const [billDiscount, setBillDiscount] = useState(0);

    const [itemDiscount, setItemDiscount] = useState(0);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [totals, setTotals] = useState({});

    const [form] = Form.useForm();

    // let props = {};

    useEffect(() => {
        calculateTotal(data);
    }, [billDiscount]);

    let queries = [
        {
            field: 'name',
            value: 'net-amount-rounding',
        },
        {
            field: 'category',
            value: 'basic',
        },
    ];

    function calculateTotal(data) {
        Settings.get(queries).then((result) => {
            let roundValue = result && result.settings.length && result.settings[0].round_value;

            let total = 0;
            let cgstAmount = 0;
            let sgstAmount = 0;
            let igstAmount = 0;
            let tcsAmount = 0;

            // Add the GST Amount
            if (!isInter) {
                data.map((item) => {
                    let cgstValue = (item.cgst / 100) * item.taxable_value;
                    let sgstValue = (item.sgst / 100) * item.taxable_value;
                    let igstValue = (item.igst / 100) * item.taxable_value;

                    cgstAmount += cgstValue;
                    sgstAmount += sgstValue;
                    igstAmount += igstValue;

                    total += item.net_amount;
                });
            } else {
                data.map((item) => {
                    let igstValue = (item.igst / 100) * item.taxable_value;

                    igstAmount += igstValue;

                    total += item.net_amount;
                });
            }

            let totalNetAmount = 0;

            // Bill discount calculation
            if (billDiscount !== 0) {
                data.map((item) => {
                    let netAmount = item.net_amount;
                    totalNetAmount += netAmount;
                });

                if (billDiscount.billDiscountType === 'percent') {
                    let discountAmount = (billDiscount.billDiscountValue / 100) * totalNetAmount;

                    total -= discountAmount;
                } else if (billDiscount.billDiscountType === 'amount') {
                    total -= billDiscount.billDiscountValue;
                }
            }

            if (total > 1000000) {
                // Find the tcs amount
                tcsAmount = 0.01 * total;

                // Add tcs amount
                total = total + tcsAmount;

                tcsAmount = Cart.getRoundedAmount(tcsAmount, 2);
            }

            //rounding net amount

            let adjArr = coinAdjustment(total, roundValue);

            total = adjArr[0];

            let roundAmount = adjArr[1];

            // function for net amount rounding
            function coinAdjustment(amount, roundValue) {
                let decArr = [];

                decArr[0] = amount;
                decArr[1] = 0;

                if (roundValue > 0 && (amount * 100) % roundValue !== 0) {
                    decArr[0] = amount * 100;
                    decArr[0] = Math.round(decArr[0] / roundValue) * (roundValue / 100);
                    decArr[1] = parseFloat((decArr[0] - amount).toFixed(2));
                }

                return decArr;
            }

            setTotals({
                total,
                roundAmount,
                cgstAmount,
                sgstAmount,
                igstAmount,
            });
        });
    }

    const showDiscountModal = () => {
        setIsModalVisible(true);
    };

    const billDiscountHandler = (values) => {
        setBillDiscount(values);
        callback(values);
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    console.log(billDiscount);

    return (
        <>
            <Table
                pagination={false}
                scroll={{ x: true }}
                rowKey={(record, key) => key}
                columns={columns}
                dataSource={data}
                // {...props}
                size="small"
                summary={(pageData) => {
                    //  #todo Below section will have to be optimized in future as there is enormous amount of rerendering happending for this
                    // component . But it works
                    let total = 0,
                        cgst_total = 0,
                        sgst_total = 0,
                        igst_total = 0,
                        tcs_amount = 0;

                    var cess_total = 0;

                    pageData.forEach((entry) => {
                        // Amount
                        let amount = entry.net_amount;

                        // Total
                        total += amount;

                        cgst_total += entry.cgst_amount;
                        sgst_total += entry.sgst_amount;
                        igst_total += entry.igst_amount;

                        cess_total += entry.cess_amount;
                    });

                    total = Math.round(total);

                    if (total > 1000000) {
                        tcs_amount = total * 0.01;
                        tcs_amount = Cart.getRoundedAmount(tcs_amount, 2);
                    }

                    // Add the tcs to it
                    total += tcs_amount;

                    return (
                        <>
                            <Modal title="Bill Discount" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                                <Form {...layout} form={form} name="new-record" onFinish={billDiscountHandler} layout="vertical">
                                    <Form.Item name="billDiscountType" label="Discount" rules={[{ required: true, message: 'Please select a type' }]}>
                                        <Radio.Group>
                                            <Radio value="percent">In %</Radio>
                                            <Radio value="amount">In Rs</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label="Value"
                                        name="billDiscountValue"
                                        rules={[{ required: true, message: 'Please input your amount' }]}
                                    >
                                        <InputNumber />
                                    </Form.Item>

                                    <Form.Item
                                        label="Value"
                                        name="bill_discount_remarks"
                                        rules={[{ required: true, message: 'Please input your remarks' }]}
                                    >
                                        <TextArea />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Modal>

                            {/* TCS Section */}
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>TCS @ 1%</Table.Summary.Cell>

                                {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                {isInter ? null : <Table.Summary.Cell></Table.Summary.Cell>}
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>{tcs_amount}</Table.Summary.Cell>
                            </Table.Summary.Row>

                            {/* Discount Row */}
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Discount</Table.Summary.Cell>

                                {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                {isInter ? null : <Table.Summary.Cell></Table.Summary.Cell>}
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Button size="small" type="dashed" onClick={showDiscountModal}>
                                        Add discount
                                    </Button>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell>
                                    <Popover content={`Bill Discount`}>
                                        <Text>
                                            {billDiscount === 0 ? (
                                                <Text>{billDiscount}</Text>
                                            ) : billDiscount.billDiscountType === 'percent' ? (
                                                <Text>{billDiscount.billDiscountValue}%</Text>
                                            ) : (
                                                <Rupee value={billDiscount.billDiscountValue}></Rupee>
                                            )}
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            {/* Round off raw */}
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Round Off</Table.Summary.Cell>

                                {/* <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell> */}
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>

                                {isInter ? (
                                    <>
                                        <Table.Summary.Cell></Table.Summary.Cell>

                                        <Table.Summary.Cell></Table.Summary.Cell>
                                    </>
                                ) : (
                                    <Fragment>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>

                                        <Table.Summary.Cell></Table.Summary.Cell>
                                    </Fragment>
                                )}

                                {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                <Table.Summary.Cell>
                                    <Popover content={`Round Off`}>
                                        <Text>
                                            <Rupee value={totals.roundAmount}></Rupee>
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            {/* //Total row */}

                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell>Total</Table.Summary.Cell>

                                {/* <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell> */}
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>

                                <Table.Summary.Cell></Table.Summary.Cell>

                                {isInter ? (
                                    <>
                                        <Table.Summary.Cell>
                                            <Popover content={`Total IGST`}>
                                                <Text>
                                                    <Rupee value={totals.igstAmount}></Rupee>
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>

                                        <Table.Summary.Cell>
                                            <Popover content={`Total CESS`}>
                                                <Text>
                                                    <Rupee value={cess_total}></Rupee>
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>
                                    </>
                                ) : (
                                    <Fragment>
                                        <Table.Summary.Cell>
                                            <Popover content={`Total SGST`}>
                                                <Text>
                                                    <Rupee value={totals.sgstAmount}></Rupee>
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Popover content={`Total CGST`}>
                                                <Text>
                                                    <Rupee value={totals.cgstAmount}></Rupee>
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>

                                        <Table.Summary.Cell>
                                            <Popover content={`Total CESS`}>
                                                <Text>
                                                    <Rupee value={cess_total}></Rupee>
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>
                                    </Fragment>
                                )}

                                {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                <Table.Summary.Cell>
                                    <Popover content={`Total`}>
                                        <Text style={{ fontSize: '18px', fontWeight: '600', color: '#FF0000' }}>
                                            <Rupee value={totals.total}></Rupee>
                                        </Text>
                                    </Popover>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </>
    );
}

/**
 * More Action for Row
 *
 * @returns
 */
function MoreAction({ callback, data }) {
    const [visible, setVisible] = useState(false);

    const handleClick = (params) => {
        console.log(params);

        switch (params.key) {
            case 'discount':
                setVisible(true);

                break;
            case 'item_1':
                break;
            default:
        }
    };

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="discount">Add Discount</Menu.Item>
            {/* <Menu.Item>Delete Item</Menu.Item> */}
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={menu} placement="bottomLeft">
                <Button size="small">
                    <MoreOutlined />
                </Button>
            </Dropdown>

            {/* Modal for Adding item Discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="More"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <DiscountModal
                    callback={(values) => {
                        setVisible(false);

                        callback(values);
                    }}
                    data={data}
                />
            </Modal>
            {/* Modal for Adding item Discount Ends */}
        </>
    );
}

const tailLayout = {
    wrapperCol: {
        // offset: 8,
        // span: 16
    },
};

function DiscountModal({ callback, data }) {
    const onFinish = (values) => {
        callback(values);
        console.log(data);
    };

    return (
        <>
            <p>Mention the discount</p>

            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                    discount_type: data.discount_type,
                    discount_value: data.discount_value,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="discount_type"
                    label="Discount"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a type',
                        },
                    ]}
                >
                    <Radio.Group defaultValue={data.discount_type}>
                        <Radio value="percent">In %</Radio>
                        <Radio value="amount">In Rs</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Value"
                    name="discount_value"
                    dependencies={['discount_type']}
                    rules={[
                        { required: true, message: 'Please input your amount' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('discount_type') === 'amount') {
                                    if (value > data.net_amount) {
                                        return Promise.reject(new Error('Discount should not exceed net amount.'));
                                    } else return Promise.resolve();
                                } else if (!value || getFieldValue('discount_type') === 'percent') {
                                    if (value > 100) {
                                        return Promise.reject(new Error('Discount should not exceed 100%.'));
                                    } else return Promise.resolve();
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        }),
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item label="Remarks" name="discount_remarks" rules={[{ required: true, message: 'Please input your remarks' }]}>
                    <TextArea />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
