import Base from './../../models/base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import FirebaseUtils from '../../utils/firebase.utils';

import EmployeeList from './components/employee-list/employee-list';

import EmployeeAdd from './components/employee-add/employee-add';

import EmployeeDetail from './components/employee-detail/employee-detail';

import { Bookings, Enquirys, DocumentLogs, Requests, Designations } from '../../models';

class Employees extends Base {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];
    }

    get getEndpoint() {
        return 'employees';
    }

    get path() {
        return `employees`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `employee`;
    }

    get columns() {
        return [
            {
                caption: 'Name',
                field: 'fname',
                key: 'name',
            },

            {
                caption: 'Designation',
                field: 'designationName',
                key: 'designationName',
            },

            // {
            //     caption: 'Designation',
            //     field: 'designation',
            //     key: 'designation',
            // },

            // {
            //     caption: 'Path',
            //     field: 'path',
            //     key: 'path',
            // },

            // {
            //     caption: 'Model',
            //     field: 'model.name',
            //     key: 'model',
            // },

            // {
            //     caption: 'Model Path',
            //     field: 'model.path',
            //     key: 'model',
            // },

            {
                caption: 'Created Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/menus/${record['id']}`}>
                <div className="card">
                    <h3 className="">{record.fname + ' ' + record.lname}</h3>

                    <h4 className="title">{record.designationName}</h4>

                    <p className="address">
                        Created on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    /**
     * 
     * 
     * @param {*} bookings 
     * @returns 
     */
    getKECandTeamleader = async (bookings) => {

        // Get all the employees
        const { employees } = await this.get();

        return bookings.map((ele) => {

            if (ele.consultant_id) {

                // Identify the kec
                const kec = employees.find((item) => item.id === ele.consultant_id)||null

                // TL 
                const teamleader = employees.find((item) => item.id === ele.teamleader_id)||null

                ele = {
                    ...ele,
                    consultant: kec?kec['fname'] + ' ' + kec['lname']:null,
                }

                // Attach the teamleader
                if (teamleader && teamleader['fname']) {

                    ele = {
                        ...ele,
                        teamleader: teamleader['fname'] + ' ' + teamleader['lname']
                    }
                }

                return ele
            } else {
                return ele
            }
        })


    }

    /**
     *  Function to update kec in bookings, request and enquiries for a transfer of booking/request from one kec to another
     * @param {*} selectedKec
     * @param {*} booking
     * @param {*} user
     */
    changeKec = async (selectedKec, booking, user, userRequest, batch) => {
        //If Booking and Booking number is present. This is used for kec change for Booking

        if (booking && booking['Booking No']) {
            const bookingRef = Bookings.getRecordReference(booking['Booking No']);

            //Updating booking with the newly selcted kec to whom the booking is transferred

            await batch.update(bookingRef, { consultant_id: selectedKec.id, teamleader_id: selectedKec.header_id });

            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs

            var param = {
                fields_changed: ['consultant_id', 'teamleader_id'],
                previous_value: {
                    consultant_id: booking.consultant_id || null,
                    teamleader_id: booking.teamleader_id || null,
                },
                model: 'bookings',
                record_id: booking['Booking No'],
                updated_value: {
                    consultant_id: selectedKec.id,
                    teamleader_id: selectedKec.header_id,
                },
                updated_by: user.id,
            };

            //Creating a new record in documnet_logs about the details of the updation

            const documentRef = DocumentLogs.getRecordReference();

            await batch.set(documentRef, this.appendDefaultValues(param));
        }

        //If Request and booking no in request is present.This is used for kec change in request
        if (userRequest && userRequest.bookingNo) {
            const booking = await Bookings.getRecord(userRequest.bookingNo);

            const bookingRef = Bookings.getRecordReference(userRequest.bookingNo);

            //Updating booking with the newly selcted kec to whom the booking is transferred

            await batch.update(bookingRef, { consultant_id: selectedKec.id, teamleader_id: selectedKec.header_id });

            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs

            var param1 = {
                fields_changed: ['consultant_id', 'teamleader_id'],
                previous_value: {
                    consultant_id: booking.consultant_id || null,
                    teamleader_id: booking.teamleader_id || null,
                },
                model: 'bookings',
                record_id: userRequest.bookingNo,
                updated_value: {
                    consultant_id: selectedKec.id,
                    teamleader_id: selectedKec.header_id,
                },
                updated_by: user.id,
            };

            //Creating a new record in documnet_logs about the details of the updation

            const documentRef = DocumentLogs.getRecordReference();

            await batch.set(documentRef, this.appendDefaultValues(param1));
        }

        //If the booking has requestId in it then the request is also updated with the new kec and teamleader id

        if (booking.requestId) {
            //Get the request record with requestid present in booking

            const request = await Requests.getRecord(booking.requestId);

            //Update the request with the newly selected kec to whom the booking is assigned

            const requestRef = Requests.getRecordReference(booking.requestId);

            await batch.update(requestRef, { consultant_id: selectedKec.id, teamleader_id: selectedKec.header_id });

            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs

            var params = {
                fields_changed: ['consultant_id', 'teamleader_id'],
                previous_value: {
                    consultant_id: request.consultant_id || null,
                    teamleader_id: request.teamleader_id || null,
                },
                model: 'requests',
                record_id: booking.requestId,
                updated_value: {
                    consultant_id: selectedKec.id,
                    teamleader_id: selectedKec.header_id,
                },
                updated_by: user.id,
            };

            //Creating a new record in documnet_logs about the details of the updation

            const documentRef1 = await DocumentLogs.getRecordReference();

            await batch.set(documentRef1, this.appendDefaultValues(params));
        }

        //If userRequest and userrequest.id is present.This is used when kec is changed from request detail page
        if (userRequest && userRequest.id) {
            //Update the request with the newly selected kec to whom the booking is assigned

            const requestRef = Requests.getRecordReference(userRequest.id);

            await batch.update(requestRef, { consultant_id: selectedKec.id, teamleader_id: selectedKec.header_id });

            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs

            var param2 = {
                fields_changed: ['consultant_id', 'teamleader_id'],
                previous_value: {
                    consultant_id: userRequest.consultant_id || null,
                    teamleader_id: userRequest.teamleader_id || null,
                },
                model: 'requests',
                record_id: userRequest.id,
                updated_value: {
                    consultant_id: selectedKec.id,
                    teamleader_id: selectedKec.header_id,
                },
                updated_by: user.id,
            };

            //Creating a new record in documnet_logs about the details of the updation

            const documentRef1 = await DocumentLogs.getRecordReference();

            await batch.set(documentRef1, this.appendDefaultValues(param2));
        }

        var query;

        //The query is used when kec is changed from Bookings

        if (booking && booking['Booking No']) {
            query = [
                {
                    field: 'Booking No',
                    value: booking['Booking No'],
                },
            ];
        }

        if (userRequest && userRequest.bookingNo) {
            //The query is used when kec is changed from Requests

            query = [
                {
                    field: 'Booking No',
                    value: userRequest.bookingNo,
                },
            ];
        }

        //The corresponding enquiry for booking is loaded
        const enquiry = await Enquirys.get(query);

        if (enquiry && enquiry.enquiries[0]) {
            //Update the request with the newly selected kec to whom the booking is assigned

            const enquiryRef = Enquirys.getRecordReference(enquiry.enquiries[0].id);

            await batch.update(enquiryRef, { consultant_id: selectedKec.id, teamleader_id: selectedKec.header_id });

            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs

            var formBody = {
                fields_changed: ['consultant_id', 'teamleader_id'],
                previous_value: {
                    consultant_id: enquiry.enquiries[0].consultant_id || null,
                    teamleader_id: enquiry.enquiries[0].teamleader_id || null,
                },
                model: 'enquiries',
                record_id: enquiry.enquiries[0].id,
                updated_value: {
                    consultant_id: selectedKec.id,
                    teamleader_id: selectedKec.header_id,
                },
                updated_by: user.id,
            };

            //Creating a new record in documnet_logs about the details of the updation

            const documentRef2 = await DocumentLogs.getRecordReference();

            await batch.set(documentRef2, this.appendDefaultValues(formBody));
        }

        //Commit all the changes

        // return await batch.commit();
    };
    ListComponent = (props) => <EmployeeList model={this}   additional_queries={[
        {
            field: 'header_id',
            value: null
        },
    ]} {...props} />;

    ModalAddComponent = (props) => <EmployeeAdd model={this} {...props} />;

    EditComponent = (props) => <EmployeeAdd {...props} edit={true} model={this} />;

    DetailComponent = () => <EmployeeDetail model={this} />;
}

export default Employees;
