import Base from './base';

import EmployeeAdd from '../modules/employees/components/employee-add/employee-add';

class FinanceBranch extends Base {
    constructor() {
        super();

        this.fields = [];
    }
    get columns() {
        return [
            {
                caption: 'Name',
                field: 'name',
                key: 'name',
            },
            {
                caption: 'Address',
                field: 'address',
                key: 'address',
            },
            {
                caption: 'iFSC',
                field: 'ifsc',
                key: 'ifsc',
            },
           
        ];
    }

    get getEndpoint() {
        return 'finance_branches';
    }

    get path() {
        return `finance_branches`;
    }

    get getName() {
        return `finance_branches`;
    }
    ModalAddComponent = (props) => <EmployeeAdd model={this} {...props} />;

    EditComponent = (props) => <EmployeeAdd  model={this} edit={true} {...props} />;
}

export default FinanceBranch;
