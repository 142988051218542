import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';

import {
    Typography,
    Table,
    Popover,
    Modal,
    message,
    Button,
    Radio,
    InputNumber,
    Skeleton,
    Tag,
    Drawer,
    Empty,
    Tabs,
    Spin,
    Menu,
    Dropdown,
    Space,
    Popconfirm,
} from 'antd';

import { Form, Input, Checkbox, Select } from 'antd';

import { useReactToPrint } from 'react-to-print';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { GlobalContext } from '../../Store';

import Rupee from '../../components/rupee/rupee';

import { ExclamationCircleOutlined, DeleteOutlined, FileTextOutlined, MoreOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../utils/firebase.utils';

import { Departments, Settings, Branches, BillDetails, Taxes, Stocks, Vehicles, Items, Requests, Bills, Bookings, Enquirys } from '../../models';

import ItemSelection from './../../modules/common/components/item-selection/item-selection';

import './service-bill.scss';
import VehicleSelection from '../../modules/common/components/vehicle-selection/vehicle-selection';
import Enquiry from '../../models/enquirys';
import { cityKeys } from '../../modules/global-config';

const { TextArea } = Input;

const { confirm } = Modal;

const { TabPane } = Tabs;

var companyLogo = require('../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const { Option } = Select;

var cityKey = cityKeys

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const tailLayout = {
    wrapperCol: {
        // offset: 8,
        // span: 16
    },
};

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export const ServiceBill = ({ match }) => {
    const [loading, setLoading] = useState(false);

    // Users
    const { user } = useContext(GlobalContext);

    const category = match.params.category;

    const dptName = match.params.department;

    const city = match.params.city;

    let history = useHistory();

    const [isgst, setIsgst] = useState(false);

    const [shipAnother, setShipAnother] = useState();

    const [packageItems, setPackageItems] = useState();

    const [department, setDepartment] = useState();

    const [data, setData] = useState();

    const [state, setState] = useState('Kerala');

    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const [visible, setVisible] = useState();

    const [vehicleVisible, setVehicleVisible] = useState();

    const [vehicles, setVehicles] = useState();

    const [vehicleLoaded, setVehicleLoaded] = useState(false);

    const [selected, setSelected] = useState();

    const [editCount, setEditCount] = useState(0);

    const [options, setOptions] = useState();

    const [partsloaded, setPartsloaded] = useState();

    const [tableLoading, setTableLoading] = useState();

    const [billDiscount, setBillDiscount] = useState(0);

    const [newCustomer, setNewCustomer] = useState(false);

    const [requests, setRequests] = useState([]);

    var [vehicleData, setVehicleData] = useState();

    const print = useRef();

    const [lastNumber, setLastNumber] = useState({});

    const [form] = Form.useForm();

    const [round, setRound] = useState();

    const [branch, setBranch] = useState();

    let count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    useEffect(() => {
        getRounding();
        loadBranch();

        //getInvoiceNumber();
        // loadPackages();
        getItems();
        getVehicles();
        loadRequests();
    }, []);

    console.log(user);

    /**
     * Get NET_AMOUNT decimal rounding
     */

    const getRounding = () => {
        let query = [
            {
                field: 'name',
                value: 'calculation-rounding',
            },
        ];
        Settings.get(query).then((res) => {
            setRound(res.settings[0]);
        });
    };

    /**
     * Get the master data to show options for the user
     */
    function getItems() {
        setPartsloaded(true);

        let qry = [
            {
                field: 'category',
                value: category,
            },
        ];

        let query = [
            {
                field: 'dealerCode',
                value: cityKey[city],
            },
        ];
        Items.get(qry)
            .then((result) => {
                return result.items;
            })
            .then((items) => {
                return Stocks.get(query).then((response) => {
                    return items.map((item, index) => {
                        let stock = response.stocks.filter((i) => i.item_id === item.id);
                        return { ...item, ...stock[0] };
                    });
                });
            })
            .then((master) => {
                // filtering for stock exist
                master &&
                    master.map((item) => {
                        if (!item.price) {
                            item.stockNotExist = true;
                        }
                    });

                setOptions(master);
                setPartsloaded(false);
                // Vehicles.getData(query).then((v) => {

                //     master = [].concat(master, v)
                //     setOptions(master)
                //     setPartsloaded(false);
                // })
            });
    }

    /**
     * get Vehicles to be listed for user
     */

    function getVehicles() {
        //  setVehicleLoaded(true);

        let query = [
            {
                field: 'dealerCode',
                value: cityKey[city],
            },
            {
                field: 'update',
                operator: 'in',
                value: ['status', 'new'],
            },
        ];

        Vehicles.get(query).then((result) => {
            let vehicles = result.vehicles.filter((v) => !v.allocated);
            vehicles = vehicles.filter((v) => v.stock_status !== 'in-transit');

            setVehicles(vehicles);
            //   setVehicleLoaded(false);
        });
    }

    /*
     * Listing pre-registered customer
     */
    const loadRequests = () => {
        setLoading(true);
        let query = [
            {
                field: 'dealerCode',
                value: cityKey[city],
            },
            {
                field: 'status',
                operator: '!=',
                value: 'removed'
            },
        ];
        Requests.get(query).then((res) => {

            let rq = res.requests.filter((request) => request.delivery && request.delivery.settlementTaken);

            setRequests(rq);
            setLoading(false);
        });
    };

    //setting up simple cart
    const dataHandler = (items) => {
        setLoading(true);

        if (dptName === 'Sales') {
            getInvoiceNumber(items[0].category);
        } else if (dptName === 'Service') {
            getInvoiceNumber('service-' + items[0].category);
        }

        return Promise.all(
            items.map((item) => {
                return Taxes.getRecord(item.tax_id).then((res) => {
                    item = { ...item, ...res };
                    return BillDetails.getDetailedPricing(item, round.round);
                });
            })
        ).then((data) => {
            setInvoiceLoading(false);
            setData(data);

            setLoading(false);
        });
    };

    //Get vehicle pricing
    const vehicleHandler = (item) => {
        setLoading(true);

        getInvoiceNumber('basic');

        var dealerCode = item['dealerCode'];
        /*
         * Get the amount rounding setting
         *
         */
        return Settings.getValue('calculation-rounding')
            .then(async (settings) => {
                item.round = settings.round;

                // item.vinNo = request.allocation.vinNo;

                /*
                 * Call for calculating amounts [net_amount, discount, tax, ..etc]
                 *
                 */
                var result = { ...(await getVehicleDetailedPricing(item, dealerCode)), ...item };

                return result;
            })
            .then((result) => {
                var arr = [];
                arr.push(result);

                setInvoiceLoading(false);

                setVehicleData(arr);

                setVehicleLoaded(true);

                setLoading(false);
            });

        // });
    };

    /**
     *
     *
     * @param {*} record
     * @returns
     */
    const getVehicleDetailedPricing = (record, dealerCode) => {
        // For each record we have to check if the price is including tax or notification

        // If its including tax , we have reverse calculate to identify the basic price , tax and its values

        // Load the item master

        //rounding value
        let round = record.round;

        //deleting cart fields for re-calculating those
        delete record['net_amount'];
        delete record['total'];
        delete record['taxable_value'];
        delete record['igst'];
        delete record['cgst'];
        delete record['sgst'];
        delete record['price'];
        delete record['rate'];

        //get item master and stock
        // return this.loadFunction.getVehicleItem({ vinNo: record.vinNo }).then((item) => {

        return loadFunction('getVehicleItem', { id: record['Vin Number'], dealerCode: dealerCode }).then((item) => {
            // In case of price | tax_id is not in record because of there is no stock of Accessory
            // if (!item.price || !item.tax_id) {
            //     return record;
            // }

            console.log(item);

            let tax = {
                cgst: item.pricing.cgst,
                sgst: item.pricing.sgst,
                igst: item.pricing.igst,
                cess: item.pricing.cess,
            };

            // Load the tax breakup from the table
            // return Taxes.getRecord(item.tax_id).then((tax) => {
            record = {
                quantity: 1,
                ...record,
                ...item,
                cgst: tax.cgst,
                sgst: tax.sgst,
                igst: tax.igst,
                cess: tax.cess,
            };

            return getVehicleTaxCalculation(record, item, tax, round);
            // });
        });
    };

    //Function for parseFloat and rounding decimal
    const getRoundedAmount = (value, round) => {
        value = parseFloat(value.toFixed(round));

        return value;
    };
    /**
     * Function identified the net amount of the item
     * according to if the item is tax inclusive
     *
     * @param {*} record
     * @param {*} item
     * @param {*} tax
     * @returns
     */
    const getVehicleTaxCalculation = (record, item, tax, round) => {
        // Find the non taxable value

        record.price = parseFloat(record.pricing.sellingPrice);

        let rate = record.price;

        record.rate = getRoundedAmount(rate, round);

        let total = record.rate * 1;

        record.total = getRoundedAmount(total, round);

        //Finding discount amount and taxable

        if (record.discount_type && record.discount_type === 'percent') {
            let discountAmount = (record.discount_value / 100) * record.total;

            record.discount_amount = getRoundedAmount(discountAmount, round);

            record.discount_percent = record.discount_value;

            record.taxable_value = record.total - record.discount_amount;
        } else if (record.discount_type && record.discount_type === 'amount') {
            record.discount_amount = record.discount_value;

            record.discount_percent = (record.discount_amount / record.total) * 100;

            record.discount_percent = getRoundedAmount(record.discount_percent);

            record.taxable_value = record.total - record.discount_amount;
        } else {
            record.taxable_value = record.total;
        }

        record.taxable_value = getRoundedAmount(record.taxable_value, round);

        //finding tax amount
        let cgstAmount = (record.taxable_value * record.cgst) / 100;
        record.cgst_amount = getRoundedAmount(cgstAmount, round);

        let sgstAmount = (record.taxable_value * record.sgst) / 100;
        record.sgst_amount = getRoundedAmount(sgstAmount, round);

        let igstAmount = (record.taxable_value * record.igst) / 100;
        record.igst_amount = getRoundedAmount(igstAmount, round);

        let cessAmount = (record.taxable_value * record.cess) / 100;
        record.cess_amount = getRoundedAmount(cessAmount, round);

        // Finding net-amount
        let netAmount = record.taxable_value + cgstAmount + sgstAmount + cessAmount;

        record.net_amount = getRoundedAmount(netAmount, round);

        record.ex_showroom = record.pricing.exShowroom

        return record;
    };

    const loadFunction = (endpoint, formBody) => {
        var firebase = FirebaseUtils.getFirebaseObject();

        var callableFunction = firebase.functions().httpsCallable(endpoint);

        return callableFunction(formBody).then((result) => {
            return result.data;
        });
    };

    // For brach details to add invoice
    const loadBranch = () => {
        var queries = [
            {
                field: 'dealer_code',
                value: cityKey[city],
            },
        ];
        Branches.get(queries).then((result) => {
            setBranch(result.branches[0]);

            let queries = [
                {
                    field: 'branch_id',
                    value: result.branches[0].id,
                },
            ];

            if (dptName === 'Sales') {
                queries.push({
                    field: 'description',
                    value: `Department Handling Sales`,
                });
            } else if (dptName === 'Service') {
                queries.push({
                    field: 'description',
                    value: `Department Handling Services`,
                });
            }

            //get department details
            return Departments.get(queries).then((result) => {
                setDepartment(result.departments);
            });
        });
    };

    // if item is a package
    const loadPackages = () => {
        setInvoiceLoading(true);

        //filtering packages from selected data
        let pi = data.filter((i) => i.package_status === true);

        if (pi.length > 0) {
            return Promise.all(
                pi.map((pkgItem) => {
                    let query = [
                        {
                            field: 'item_id',
                            value: pkgItem.item_id,
                        },
                    ];

                    return FirebaseUtils.getListing('central', query, 'package_details').then((result) => {
                        result.package_details = result.package_details.filter((i) => i.status !== 'removed' && i.active !== false);

                        return Promise.all(
                            result.package_details.map((pkg) => {
                                return FirebaseUtils.getItemJoinStockById({ id: pkg.sub_item_id, dealerCode: data[0].dealerCode }).then((item) => {
                                    return Taxes.getRecord(item.tax_id).then((tax) => {
                                        item = {
                                            ...item,
                                            item_id: item.id,
                                            cgst: tax.cgst,
                                            sgst: tax.sgst,
                                            igst: tax.igst,
                                            customerID: pkgItem.customerID,
                                            bookingNo: pkgItem.bookingNo,
                                            dealerCode: pkgItem.dealerCode,
                                            quntity: item.quantity || 1,
                                            name: item.part_name,
                                            type: item.category,
                                            id: pkgItem.id,
                                            request: pkgItem.request,
                                        };

                                        if (pkg.type === 'quantity') {
                                            item.quantity = pkg.value;
                                        }

                                        return BillDetails.getDetailedPricing(item, round.round);
                                    });
                                });
                            })
                        );
                    });
                })
            ).then((result) => {
                let array = result.flat(1);
                setPackageItems(array);
                setInvoiceLoading(false);
            });
        }
    };

    /**
     * Editable rate
     * @value
     */

    data &&
        data.map((item, key) => {
            item.callback = (value) => {
                if (value === '') {
                } else if (isNaN(value) || value.includes('e') || value.includes('E')) {
                    message.warning('Rate should be a number');
                } else {
                    item.rate = parseFloat(value);

                    let totalTax = item.cgst + item.sgst;

                    item.price = parseFloat(item.rate + parseFloat((totalTax / 100) * item.rate));

                    let i = { ...BillDetails.getDetailedPricing(item, round.round) };

                    item.sgst_amount = i.sgst_amount;
                    item.cgst_amount = i.cgst_amount;
                    item.igst_amount = i.igst_amount;
                    item.net_amount = i.net_amount;
                    item.taxable_value = i.taxable_value;
                    item.total = i.total;

                    if (i.discount_amount) {
                        item.discount_amount = i.discount_amount;
                        item.discount_percent = i.discount_percent;
                    }
                }

                setEditCount((prev) => {
                    return prev + 1;
                });
            };
        });

    function getInvoiceNumber(category) {
        setInvoiceLoading(true);

        FirebaseUtils.getBillOrder(city, category, moment().valueOf())
            .then((res) => {
                let id = res.prefix + (res.last_number + 1) + res.post_fix;

                setLastNumber({ id: res.id, lastNumber: id, numberOnly: res.last_number + 1 });

                setInvoiceLoading(false);
            })
            .catch(() => {
                message.error('Getting Bill Number breaks, Try again! .');
            });
    }

    // Overriding function for discount
    data &&
        data.map((item, key) => {
            item.discountCallback = (discount) => {
                console.log(discount);

                var val = {
                    ...item,
                    ...discount,
                };

                data[key] = val;

                return dataHandler(data);
            };

            item.quantityCallback = (quantity) => {
                var val = {
                    ...item,
                    quantity: quantity,
                };

                data[key] = val;

                return dataHandler(data);
            };

            item.deleteEntryCallBack = () => {
                let val = data;
                if (key > -1) {
                    val.splice(key, 1);
                }
                return dataHandler(val);
            };
        });

    //Overriding function for discount
    vehicleData &&
        vehicleData.map((item, key) => {
            item.discountCallback = (discount) => {
                console.log(discount);

                var val = {
                    ...item,
                    ...discount,
                };

                vehicleData[key] = val;

                return vehicleHandler(vehicleData[key]);
            };
        });

    function calculatedAmount(amt, sgst, cgst) {
        let cgst1 = 0;
        let sgst1 = 0;
        if (cgst) {
            cgst1 = amt * (cgst / 100);
        }
        if (sgst) {
            sgst1 = amt * (sgst / 100);
        }
        let total = amt + parseFloat(cgst1) + parseFloat(sgst1);
        return total;
    }

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    const getDateAndTime = () => {
        let date = new Date();
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    /**
     *
     */
    function onSubmit(values) {
        handleGenericSettlement(values);
    }

    /**
     * Close the settlement Method
     */
    const handleGenericSettlement = (values, request) => {
        confirm({
            title: 'Raise Invoice',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to bill these items, This action is one time',
            onOk() {
                console.log('OK');

                setLoading(true);
                if (vehicleData) {
                    showBill(values);
                } else {
                    closeFile(values);
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const showBill = async (values) => {
        //setting up customer details
        let request;
        if (values && values.customerID) {
            request = requests.find((req) => req.customer.customerID === values.customerID);
        } else {
            request = null;
        }
        const hide = message.loading('Creating Bill...', 0);

        let discount;

        if (billDiscount !== 0) {
            discount = {
                billDiscountType: billDiscount.billDiscountType,
                billDiscountValue: billDiscount.billDiscountValue,
            };
        } else {
            discount = null;
        }

        let customer;

        if (request) {
            let enquiry;

            let query = [
                {
                    field: 'Booking No',
                    value: request.bookingNo,
                },
            ];

            await Enquirys.get(query).then((result) => {
                enquiry = result.enquiries[0];
            });

            customer = {
                customerID: request.customer.customerID,
                customer_name: request.customer.customerName || null,
                customer_address: request.customer.place || null,
                customer_phone: request.customer.phone || null,
                customer_email: request.email || enquiry['Customer Email'],
                customer_postal_code: request.postal_code || null,
                ship_to_name: request.customer.customerName || null,
                ship_to_address: request.customer.place || null,
                ship_to_phone: request.customer.phone || null,
                ship_to_email: request.email || null,
                ship_to_postal_code: request.postal_code || null,
            };
        } else {
            customer = {
                customerID: values.customerID,
                customer_name: values.customer_name,
                customer_address: values.customer_address,
                customer_phone: values.customer_phone,
                customer_email: values.customer_email,
                customer_postal_code: values.customer_postal_code,
                ship_to_name: values.ship_to_name || values.customer_name,
                ship_to_address: values.ship_to_address || values.customer_address,
                ship_to_phone: values.ship_to_phone || values.customer_phone,
                ship_to_email: values.ship_to_email || values.customer_email,
                ship_to_postal_code: values.ship_to_postal_code || values.customer_postal_code,
            };
        }

        vehicleData = vehicleData.map((item) => {
            item.bookingNo = item['Booking No'];
            item.customerID = item['Cust ID'];
            item = {
                ...item,
                isNotCart: true,
                isNotBooking: true,
                name: item['Variant'],
                type: 'basic',
                vinNo: item['Vin Number'],
                request: null,
            };

            if (request) {
                item['request'] = {
                    customer: {
                        customerName: request.customer.customerName,
                        customerID: request.customer.customerID,
                    },
                };
            } else {
                item['request'] = {
                    customer: {
                        customerName: values.customer_name,
                        customerID: values.customerID,
                    },
                };
            }

            return item;
        });

        let formBody = {
            ...discount,
            ...customer,

            selectedRecords: vehicleData,
            lastNumber: lastNumber,
            user: user,
            city: city,
            department: department[0],
            branch_id: branch.id,
            supplier_id: branch.vehicle_supplier_id,
            ...values,
            vin_no: vehicleData[0]['Vin Number'],
            attached_with: 'independent',
            dealerCode: cityKey[city],
            requestId: null,
        };

        // Complete Billing at backend
        console.log(formBody);

        Bills.loadFunction('createBill', formBody).then((result) => {
            // Once done , Move file to
            if (request) {
                Requests.completeBilling(request).then(() => {
                    // callback();
                    message.success('Bill has been successfully created');

                    setLoading(false);

                    hide();

                    history.replace(`/bill/${result.bill_id}`);
                });
            } else {
                message.success('Bill has been successfully created');

                setLoading(false);

                hide();

                history.replace(`/bill/${result.bill_id}`);
            }
        });
    };

    /**
     * Checking settlement closed for the request
     *
     * @param id - requestId
     */
    const checkRequestSettlement = (requestId) => {
        return Requests.getRecord(requestId).then((res) => {
            res = res.requests.filter((data) => data.status !== 'removed');

            if (res.delivery.settlementTaken) {
                return true;
            } else {
                return false;
            }
        });
    };

    /**
     * Close the file
     */
    async function closeFile(values) {
        //Attaching package items
        loadPackages();

        const hide = message.loading('Creating Bill...', 0);

        //setting up customer details
        let request;
        if (values && values.customerID) {
            request = requests.find((req) => req.customer.customerID === values.customerID);
        } else {
            request = null;
        }

        /**
         * Checking settlement closed for the request
         *
         */
        if (request) {
            let settlmentTaken = await checkRequestSettlement(request.id);

            if (!settlmentTaken) {
                setLoading(false);
                hide();
                message.warning('Settlement not taken!');
                return 'Settlement not taken';
            }
        }

        let discount;
        if (billDiscount !== 0) {
            discount = {
                billDiscountType: billDiscount.billDiscountType,
                billDiscountValue: billDiscount.billDiscountValue,
            };
        } else {
            discount = null;
        }

        //Selecting supplier
        let supplier_id = null;
        if (data[0].category === 'Accessory') {
            supplier_id = branch.accessory_supplier_id;
        } else if (data[0].category === 'VAS') {
            supplier_id = branch.vas_supplier_id;
        } else if (data[0].category === 'vehicle') {
            supplier_id = branch.vehicle_supplier_id;
        }

        //filtering to avoid Package head

        let d = data.filter((dt) => dt.package_status !== true);

        let selectedRecords = [
            ...d,
            // ...packageItems
        ];

        if (packageItems && packageItems.length) {
            selectedRecords = [].concat(selectedRecords, packageItems);
        }

        let customer;
        if (request) {
            customer = {
                customerID: request.customer.customerID,
                customer_name: request.customer.customerName || null,
                customer_address: request.customer.place || null,
                customer_phone: request.phone || null,
                customer_email: request.email || null,
                customer_postal_code: request.postal_code || null,
                ship_to_name: request.customer.customerName || null,
                ship_to_address: request.customer.place || null,
                ship_to_phone: request.phone || null,
                ship_to_email: request.email || null,
                ship_to_postal_code: request.postal_code || null,
            };
        } else {
            customer = {
                customer_name: values.customer_name,
                customer_address: values.customer_address,
                customer_phone: values.customer_phone,
                customer_email: values.customer_email,
                customer_postal_code: values.customer_postal_code,
                ship_to_name: values.ship_to_name || values.customer_name,
                ship_to_address: values.ship_to_address || values.customer_address,
                ship_to_phone: values.ship_to_phone || values.customer_phone,
                ship_to_email: values.ship_to_email || values.customer_email,
                ship_to_postal_code: values.ship_to_postal_code || values.customer_postal_code,
            };
        }

        //Setting up body
        let formBody = {
            ...discount,
            ...customer,
            selectedRecords: selectedRecords,
            selectedIds: null,
            lastNumber: lastNumber,
            user: user,
            department: department[0],
            supplier_id: supplier_id,
            payment_status: null,
            ...values,
            notFromCart: true,
            isPayout: false,
            dealerCode: cityKey[city],
            branch_id: branch.id,
            city: city,
            attached_with: 'independent',
            request: null,
        };

        FirebaseUtils.generateVasBill(formBody).then((result) => {
            callback();

            setLoading(false);

            history.push(`/bill/${result.data}`);

            hide();
        });
    }

    const discountSubmit = (values) => {
        setBillDiscount({ ...values });
    };

    //customer form handling
    function callback(key) {
        if (key === '1') {
            setNewCustomer(false);
        } else {
            setNewCustomer(true);
        }
    }

    /**
     * More Action for Row
     *
     * @returns
     */
    function MoreAction({ callback, data }) {
        const [visible, setVisible] = useState(false);

        const handleClick = (params) => {
            switch (params.key) {
                case 'discount':
                    setVisible(true);

                    break;
                case 'item_1':
                    break;
                default:
            }
        };

        const menu = (
            <Menu onClick={handleClick}>
                <Menu.Item key="discount">Add Discount</Menu.Item>
                {/* <Menu.Item>Delete Item</Menu.Item> */}
            </Menu>
        );

        return (
            <>
                <Dropdown overlay={menu} placement="bottomLeft">
                    <Button size="small">
                        <MoreOutlined />
                    </Button>
                </Dropdown>

                {/* Modal for Adding item Discount */}
                <Modal
                    destroyOnClose={true}
                    footer={null}
                    title="More"
                    visible={visible}
                    okText="Okay"
                    onOk={() => {
                        setVisible(false);
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                >
                    <DiscountModal
                        callback={(values) => {
                            setVisible(false);

                            callback(values);
                        }}
                        data={data}
                    />
                </Modal>
                {/* Modal for Adding item Discount Ends */}
            </>
        );
    }
    function DiscountModal({ callback, data }) {
        const onFinish = (values) => {
            callback(values);

            data = {
                ...values,
                ...data,
            };
        };

        return (
            <>
                <p>Mention the discount</p>

                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                        discount_type: data.discount_type,
                        discount_value: data.discount_value,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="discount_type"
                        label="Discount"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a type',
                            },
                        ]}
                    >
                        <Radio.Group defaultValue={data.discount_type}>
                            <Radio value="percent">In %</Radio>
                            <Radio value="amount">In Rs</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Value"
                        name="discount_value"
                        dependencies={['discount_type']}
                        rules={[
                            { required: true, message: 'Please input your amount' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('discount_type') === 'amount') {
                                        if (value > data.net_amount) {
                                            return Promise.reject(new Error('Discount should not exceed net amount.'));
                                        } else return Promise.resolve();
                                    } else if (!value || getFieldValue('discount_type') === 'percent') {
                                        if (value > 100) {
                                            return Promise.reject(new Error('Discount should not exceed 100%.'));
                                        } else return Promise.resolve();
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item label="Remarks" name="discount_remarks" rules={[{ required: true, message: 'Please input your remarks' }]}>
                        <TextArea />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );
    }

    const vehicle_igst_columns = [
        {
            title: '#',
            width: 20,
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Vehicle',
            dataIndex: 'Variant',
            render: (text, record) => {
                return <div>{record['Variant']}</div>;
            },
        },
        {
            title: 'Rate',
            render: (text, record) => {
                return record.editable === true ? (
                    <Text editable style={{ color: 'red' }}>
                        {record.rate}
                    </Text>
                ) : (
                    <Text>{record.rate}</Text>
                );
            },
        },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? record.discount_value + '%'
                                : record.discount_type && record.discount_type === 'amount'
                                    ? '₹' + record.discount_value
                                    : 0}
                        </Popover>
                    </div>
                );
            },
            width: 150,
        },

        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>
                            {((parseFloat(record.quantity) ? parseFloat(record.quantity) : 1) * parseFloat(record.taxable_value)).toFixed(2)}
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'IGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.igst_amount ? record.igst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.igst ? record.igst : 0}%</div>
                    </div>
                );
            },
            width: 150,
        },

        {
            title: 'CESS',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cess_amount ? record.cess_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cess ? record.cess : 0}%</div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'Net Amount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <div style={{ height: '20px' }}>{text.net_amount}</div>
                    </div>
                );
            },
            width: 200,
        },
        {
            title: 'Actions',
            render: (record) => {
                return (
                    <>
                        <MoreAction callback={record.callback} data={record} />
                    </>
                );
            },
        },
    ];

    const vehicle_columns = [
        {
            title: '#',
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Vehicle',
            dataIndex: 'Variant',
            render: (text, record) => {
                return <div>{record['Variant']}</div>;
            },
        },
        {
            title: 'Rate',
            render: (text, record) => {
                return record.editable === true ? (
                    <Text
                        editable={{
                            onChange: record.callback,
                            tooltip: 'click to edit rate',
                        }}
                    >
                        {record.rate}
                    </Text>
                ) : (
                    <Text>{record.rate}</Text>
                );
            },
        },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? record.discount_value + '%'
                                : record.discount_type && record.discount_type === 'amount'
                                    ? '₹' + record.discount_value
                                    : 0}
                        </Popover>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.taxable_value}</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'SGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>
                            {(parseFloat(record.taxable_value) ? parseFloat((record.sgst / 100) * record.taxable_value) : 0).toFixed(2)}
                        </div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.sgst ? record.sgst : 0}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'CGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>
                            {(parseFloat(record.taxable_value) ? parseFloat((record.cgst / 100) * record.taxable_value) : 0).toFixed(2)}
                        </div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cgst ? record.cgst : 0}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'CESS',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>
                            {(parseFloat(record.taxable_value) ? parseFloat((record.cess / 100) * record.taxable_value) : 0).toFixed(2)}
                        </div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cess ? record.cess : 0}%</div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'Net Amount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{text.net_amount}</div>;
            },
            width: 150,
        },
        {
            title: 'Actions',
            render: (record) => {
                return (
                    <>
                        <MoreAction callback={record.discountCallback} data={record} />
                    </>
                );
            },
        },
    ];

    const igst_columns = [
        {
            title: '#',
            width: 20,
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            width: 220,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div>{text ? text : record['part_name']}</div>
                        {record.package_status === true ? (
                            <Tag style={{ marginLeft: '10px' }} color="magenta">
                                Package
                            </Tag>
                        ) : null}
                        {/* {
                            record.updatedType == 'BILLED (VAS)' && !printTab ?
                                <div style={{ marginLeft: '10px' }}><FileTextOutlined /></div>
                                :
                                null
                        } */}
                    </div>
                );
            },
        },
        {
            title: 'Rate',
            render: (text, record) => {
                return record.editable === true ? (
                    <Text editable style={{ color: 'red' }}>
                        {record.rate}
                    </Text>
                ) : (
                    <Text>{record.rate}</Text>
                );
            },
        },
        {
            title: 'Quantity',
            // dataIndex: 'quantity',
            render: (value, key, index) => {
                return (
                    <Fragment>
                        <Select
                            defaultValue={value.quantity}
                            style={{ width: 50 }}
                            onChange={(v) => {
                                console.log(v);

                                value.quantityCallback(v);
                            }}
                        >
                            {count.map((ele, key) => (
                                <Option key={key} value={ele}>
                                    {ele}
                                </Option>
                            ))}
                        </Select>
                    </Fragment>
                );
            },
        },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? record.discount_value + '%'
                                : record.discount_type && record.discount_type === 'amount'
                                    ? '₹' + record.discount_value
                                    : 0}
                        </Popover>
                    </div>
                );
            },
            width: 150,
        },

        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>
                            {((parseFloat(record.quantity) ? parseFloat(record.quantity) : 1) * parseFloat(record.taxable_value)).toFixed(2)}
                        </div>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'IGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.igst_amount ? record.igst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.igst ? record.igst : 0}%</div>
                    </div>
                );
            },
            width: 150,
        },

        {
            title: 'Net Amount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <div style={{ height: '20px' }}>{text.net_amount}</div>
                    </div>
                );
            },
            width: 200,
        },
        {
            title: 'Actions',
            render: (value, index, ele) => {
                return (
                    <Space>
                        <Popconfirm
                            title="Are you sure you want to delete this ? "
                            onConfirm={() => value.deleteEntryCallBack()}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" className="submit-button" disabled={value.updatedType == 'BILLED (VAS)' ? true : false}>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                        <MoreAction callback={value.discountCallback} data={value} />
                    </Space>
                );
            },
        },
    ];

    const columns = [
        {
            title: '#',
            render: (value, item, index) => {
                return <div style={{ height: '20px' }}>{index + 1}</div>;
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div style={{ display: 'flex' }}>
                            <div>{text ? text : record['part_name']}</div>
                            {record.package_status === true ? (
                                <Tag style={{ marginLeft: '10px' }} color="magenta">
                                    Package
                                </Tag>
                            ) : null}
                            {/* {
                            record.updatedType == 'BILLED (VAS)' && !printTab ?
                                <div style={{ marginLeft: '10px' }}><FileTextOutlined /></div>
                                :
                                null
                        } */}
                        </div>
                        <small>{record.part_number}</small>
                    </Fragment>
                );
            },
        },
        {
            title: 'Rate',
            render: (text, record) => {
                return record.editable === true ? (
                    <Text
                        editable={{
                            onChange: record.callback,
                            tooltip: 'click to edit rate',
                        }}
                    >
                        {record.rate}
                    </Text>
                ) : (
                    <Text>{record.rate}</Text>
                );
            },
        },
        {
            title: 'Quantity',
            // dataIndex: 'quantity',
            render: (value, key, index) => {
                return (
                    <Fragment>
                        <Select
                            defaultValue={value.quantity}
                            style={{ width: 50 }}
                            onChange={(v) => {
                                console.log(v);

                                value.quantityCallback(v);
                            }}
                        >
                            {count.map((ele, key) => (
                                <Option key={key} value={ele}>
                                    {ele}
                                </Option>
                            ))}
                        </Select>
                    </Fragment>
                );
            },
        },

        {
            title: 'Total',
            dataIndex: 'total',
        },

        {
            title: 'Discount',
            render: (text, record) => {
                return (
                    <div style={{ height: '20px' }}>
                        <Popover content={`Item Discount`}>
                            {record.discount_type && record.discount_type === 'percent'
                                ? record.discount_value + '%'
                                : record.discount_type && record.discount_type === 'amount'
                                    ? '₹' + record.discount_value
                                    : 0}
                        </Popover>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Taxable Value',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.taxable_value}</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'SGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.sgst_amount ? record.sgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.sgst ? record.sgst : 0}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'CGST',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ height: '20px' }}>{record.cgst_amount ? record.cgst_amount : 0}</div>
                        <div style={{ height: '20px', color: 'grey' }}>@{record.cgst ? record.cgst : 0}%</div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: 'Net Amount',
            render: (text, record) => {
                return <div style={{ height: '20px' }}>{text.net_amount}</div>;
            },
            width: 150,
        },

        {
            title: 'Actions',
            render: (value, index, ele) => {
                return (
                    <Space>
                        <Popconfirm
                            title="Are you sure you want to delete this ? "
                            onConfirm={() => value.deleteEntryCallBack()}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" className="submit-button" disabled={value.updatedType == 'BILLED (VAS)' ? true : false}>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                        <MoreAction callback={value.discountCallback} data={value} />
                    </Space>
                );
            },
        },
    ];

    return (
        <div className="bill-preview-page">
            <Drawer
                className="accessory-drawer"
                width="100%"
                destroyOnClose={true}
                title="Choose customer choice"
                visible={vehicleVisible}
                onClose={() => {
                    setVehicleVisible(false);
                }}
            >
                <VehicleSelection
                    vehicles={vehicles}
                    callback={(item) => {
                        setVehicleVisible(false);
                        setInvoiceLoading(true);
                        vehicleHandler(item);
                    }}
                />
            </Drawer>
            <Drawer
                className="accessory-drawer"
                width="100%"
                destroyOnClose={true}
                title="Choose customer choices"
                visible={visible}
                onClose={() => {
                    setVisible(false);
                }}
            >
                <ItemSelection
                    selected={data}
                    choices={data}
                    options={options}
                    callback={(items) => {
                        setVisible(false);
                        setInvoiceLoading(true);
                        dataHandler(items);
                    }}
                />
            </Drawer>
            <div className="invoice-container">
                <div className="page-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div>
                        <img className="logo-welcome" src={companyLogo} alt="Logo" />
                        <Title level={3}>Invoice # {lastNumber.lastNumber}</Title>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {/* <div style={{ border: '1px dashed #d9d9d9', padding: '4px 15px', marginRight: '10px' }}>Bill No :- {billNumber}</div> */}
                        {/* <Button type="dashed" className="print-button" onClick={handlePrint}>
                            Print Invoice
                        </Button> */}
                    </div>
                    <div>
                        {match.params.category !== 'Vehicle' || data ? null : (
                            <Button
                                className="add-button"
                                type="dashed"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setVehicleVisible(true);
                                }}
                            >
                                Add Vehicle
                            </Button>
                        )}

                        {match.params.category === 'Vehicle' || partsloaded || vehicleData ? null : (
                            <Button
                                className="add-button"
                                type="dashed"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setVisible(true);
                                }}
                            >
                                Add Data
                            </Button>
                        )}
                    </div>
                </div>

                {/* <div className="bill-header">
                    {request.id && <CustomerInfo request={request} />}

                    <Button size="small" onClick={refresh}>
                        Refresh
                    </Button>
                </div> */}

                <div>
                    {loading ? (
                        <Skeleton />
                    ) : !vehicleLoaded && !data ? (
                        <>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            <p style={{ fontSize: '16px', textAlign: 'center' }}>Nothing added yet</p>
                        </>
                    ) : vehicleLoaded ? (
                        <>
                            <Summary
                                data={vehicleData}
                                columns={state !== 'Kerala' ? vehicle_igst_columns : vehicle_columns}
                                isInter={state !== 'Kerala' ? true : false}
                                callback={discountSubmit}
                                editCount={editCount}
                                loading={loading}
                            />
                        </>
                    ) : data ? (
                        <Summary
                            data={data}
                            columns={state !== 'Kerala' ? igst_columns : columns}
                            isInter={state !== 'Kerala' ? true : false}
                            callback={discountSubmit}
                            editCount={editCount}
                            loading={loading}
                        />
                    ) : null}
                </div>
            </div>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="modal-footer card">
                    <Title level={3}>Create Bill</Title>

                    <p>Enter the below information to continue creating the bill .</p>
                    <Tabs activeKey={newCustomer ? '2' : '1'} onChange={callback}>
                        <TabPane tab="Select Customer" key="1" />
                        <TabPane tab="New Customer" key="2" />
                    </Tabs>

                    {console.log(newCustomer)}
                    {!newCustomer ? (
                        <Form
                            {...layout}
                            form={form}
                            name="new-record"
                            onFinish={onSubmit}
                            layout="vertical"
                            onFieldsChange={(fields) => {
                                let field = fields[0];

                                if (field) {
                                    if (field.name[0] === 'is_gst_customer') {
                                        setIsgst(field.value);
                                    }

                                    if (field.name[0] === 'is_gst_customer') {
                                        setIsgst(field.value);
                                    }

                                    if (field.name[0] === 'customer_state') {
                                        setState(field.value);
                                    }
                                } else {
                                }
                            }}
                            // validateMessages={validateMessages}
                            initialValues={{}}
                        >
                            <Form.Item
                                name={'customerID'}
                                label="Customer"
                                rules={[
                                    {
                                        message: 'Please select a customer',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Search to Select Customer"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                >
                                    {requests &&
                                        requests.map((req, key) => {
                                            return (
                                                <Option key={key} value={req.customer.customerID}>
                                                    {`${req.customer.customerName} - [ ${req.customer.customerID} ]`}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="is_gst_customer" valuePropName="checked" wrapperCol={{ span: 16 }}>
                                <Checkbox>GST Customer ?</Checkbox>
                            </Form.Item>

                            {isgst ? (
                                <Form.Item
                                    name={'gst_number'}
                                    label="GST Number"
                                    rules={[
                                        {
                                            message: 'Please enter GSTIN',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            ) : null}

                            <Form.Item name="tcsExempted" valuePropName="checked" wrapperCol={{ span: 16 }}>
                                <Checkbox>TCS Exempted ?</Checkbox>
                            </Form.Item>

                            <Form.Item
                                name={'customer_state'}
                                label="Customer State"
                                rules={[
                                    {
                                        message: 'Please select state',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                                    {['Kerala', 'Pondicherry', 'Tamil Nadu', 'Karnataka', 'Andhra Pradesh', 'Other'].map((model, key) => {
                                        return (
                                            <Option value={model} key={key}>
                                                {model}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name={'pan_number'}
                                label="Pan Number"
                                rules={[
                                    {
                                        message: 'Please enter pan number.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input style={{ width: '100%' }} placeholder="Enter pan number" />
                            </Form.Item>

                            <Form.Item
                                name={'payment_method'}
                                label="Payment&nbsp;Method"
                                rules={[
                                    {
                                        message: 'Please select payment method',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                                    {['Cash', 'Cheque', 'Debit Card', 'Credit Card', 'Net Banking', 'Other'].map((model, key) => {
                                        return (
                                            <Option value={model} key={key}>
                                                {model}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={[
                                    {
                                        message: 'Please enter remarks',
                                        required: true,
                                    },
                                ]}
                            >
                                <TextArea style={{ width: '100%' }} placeholder="Remarks" />
                            </Form.Item>

                            <Form.Item label="Consumer Note" name="consumer_note">
                                <TextArea style={{ width: '100%' }} placeholder="Consumer Note" />
                            </Form.Item>

                            {invoiceLoading ? (
                                <Button type="primary" loading>
                                    Loading
                                </Button>
                            ) : !data && !vehicleData ? (
                                <Button type="primary" disabled>
                                    Create Bill
                                </Button>
                            ) : data || vehicleData ? (
                                <Button loading={loading} htmlType="submit" type={'primary'}>
                                    Create Bill
                                </Button>
                            ) : null}
                        </Form>
                    ) : (
                        <Form
                            {...layout}
                            form={form}
                            name="new-customer-record"
                            onFinish={onSubmit}
                            layout="vertical"
                            onFieldsChange={(fields) => {
                                let field = fields[0];

                                if (field) {
                                    if (field.name[0] === 'is_gst_customer') {
                                        setIsgst(field.value);
                                    }

                                    if (field.name[0] === 'ship_to_another') {
                                        setShipAnother(field.value);
                                    }

                                    if (field.name[0] === 'customer_state') {
                                        setState(field.value);
                                    }
                                } else {
                                }
                            }}
                            // validateMessages={validateMessages}
                            initialValues={{}}
                        >
                            <Form.Item
                                name={'customer_name'}
                                label="Full Name"
                                rules={[
                                    {
                                        message: 'Please enter name',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter customer name" />
                            </Form.Item>
                            <Form.Item
                                name={'customer_email'}
                                label="Email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        message: 'Please enter email',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter customer email" />
                            </Form.Item>
                            <Form.Item
                                name={'customer_phone'}
                                label="Phone Number"
                                rules={[
                                    {
                                        message: 'Please enter phone number',
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Please enter customer phone number" />
                            </Form.Item>
                            <Form.Item
                                name={'customer_address'}
                                label="Address"
                                rules={[
                                    {
                                        message: 'Please enter address',
                                        required: true,
                                    },
                                ]}
                            >
                                <TextArea placeholder="Please enter customer address" />
                            </Form.Item>
                            <Form.Item
                                name={'customer_postal_code'}
                                label="Postal Code"
                                rules={[
                                    {
                                        message: 'Please enter postal code',
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder="Please enter customer postal code" />
                            </Form.Item>
                            <Form.Item name="ship_to_another" valuePropName="checked" wrapperCol={{ span: 16 }}>
                                <Checkbox>Ship to another address ?</Checkbox>
                            </Form.Item>

                            {shipAnother ? (
                                <Fragment>
                                    <Form.Item name={'ship_to_name'} label="Ship To Name">
                                        <Input placeholder="Please enter ship to name" />
                                    </Form.Item>
                                    <Form.Item
                                        name={'ship_to_email'}
                                        label="Ship To Email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Please enter ship to email" />
                                    </Form.Item>
                                    <Form.Item name={'ship_to_phone'} label="Ship To Phone Number">
                                        <InputNumber style={{ width: '100%' }} placeholder="Please enter ship to phone number" />
                                    </Form.Item>

                                    <Form.Item name={'ship_to_address'} label="Ship To Address">
                                        <TextArea placeholder="Please enter ship to address" />
                                    </Form.Item>
                                    <Form.Item name={'ship_to_postal_code'} label="Ship To Postal Code">
                                        <InputNumber style={{ width: '100%' }} placeholder="Please enter ship to postal code" />
                                    </Form.Item>
                                </Fragment>
                            ) : null}

                            <Form.Item name="is_gst_customer" valuePropName="checked" wrapperCol={{ span: 16 }}>
                                <Checkbox>GST Customer ?</Checkbox>
                            </Form.Item>

                            {isgst ? (
                                <Form.Item
                                    name={'gst_number'}
                                    label="GST Number"
                                    rules={[
                                        {
                                            message: 'Please enter GSTIN',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            ) : null}

                            <Form.Item name="tcsExempted" valuePropName="checked" wrapperCol={{ span: 16 }}>
                                <Checkbox>TCS Exempted ?</Checkbox>
                            </Form.Item>

                            <Form.Item
                                name={'customer_state'}
                                label="Customer State"
                                rules={[
                                    {
                                        message: 'Please select state',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} placeholder="Please select state">
                                    {['Kerala', 'Pondicherry', 'Tamil Nadu', 'Karnataka', 'Andhra Pradesh', 'Other'].map((model, key) => {
                                        return (
                                            <Option value={model} key={key}>
                                                {model}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name={'pan_number'}
                                label="Pan Number"
                                rules={[
                                    {
                                        message: 'Please enter pan number.',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input style={{ width: '100%' }} placeholder="Enter pan number" />
                            </Form.Item>

                            <Form.Item
                                name={'payment_method'}
                                label="Payment&nbsp;Method"
                                rules={[
                                    {
                                        message: 'Please select payment method',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select allowClear style={{ width: '100%' }} placeholder="Please select">
                                    {['Cash', 'Cheque', 'Debit Card', 'Credit Card', 'Net Banking', 'Other'].map((model, key) => {
                                        return (
                                            <Option value={model} key={key}>
                                                {model}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={[
                                    {
                                        message: 'Please enter remarks',
                                        required: true,
                                    },
                                ]}
                            >
                                <TextArea style={{ width: '100%' }} placeholder="Remarks" />
                            </Form.Item>
                            <Form.Item label="Consumer Note" name="consumer_note">
                                <TextArea style={{ width: '100%' }} placeholder="Consumer Note" />
                            </Form.Item>

                            {invoiceLoading ? (
                                <Button type="primary" loading>
                                    Loading
                                </Button>
                            ) : !data && !vehicleData ? (
                                <Button type="primary" disabled>
                                    Create Bill
                                </Button>
                            ) : data || vehicleData ? (
                                <Button loading={loading} htmlType="submit" type={'primary'}>
                                    Create Bill
                                </Button>
                            ) : null}
                        </Form>
                    )}
                </div>
            )}
        </div>
    );
};

function Summary({ data, columns, refresh, isInter, callback, editCount, loading }) {
    const [billDiscount, setBillDiscount] = useState(0);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [totals, setTotals] = useState({});

    const [calcLoading, setCalcLoading] = useState();

    const [form] = Form.useForm();

    let props = {};

    console.log(data);
    useEffect(() => {
        calculateTotal(data);
    }, [billDiscount, editCount, loading]);

    let queries = [
        {
            field: 'name',
            value: 'net-amount-rounding',
        },
        {
            field: 'category',
            value: 'basic',
        },
    ];

    const getRoundedAmount = (value, round) => {
        value = parseFloat(value.toFixed(round));

        return value;
    };
    function calculateTotal(data) {
        setCalcLoading(true);
        Settings.get(queries).then((result) => {
            let roundValue = result && result.settings.length && result.settings[0].round_value;

            let total = 0;
            let netAmount = 0;
            let cgstAmount = 0;
            let sgstAmount = 0;
            let igstAmount = 0;
            let tcsAmount = 0;
            if (!isInter) {
                data.map((item) => {
                    let cgstValue = (item.cgst / 100) * item.taxable_value;
                    let sgstValue = (item.sgst / 100) * item.taxable_value;
                    let igstValue = (item.igst / 100) * item.taxable_value;

                    cgstAmount += cgstValue;
                    sgstAmount += sgstValue;
                    igstAmount += igstValue;

                    total += item.net_amount;
                    netAmount += item.net_amount;
                });
            } else {
                data.map((item) => {
                    let igstValue = (item.igst / 100) * item.taxable_value;

                    igstAmount += igstValue;

                    netAmount += item.net_amount;
                });
            }

            let totalNetAmount = 0;

            if (billDiscount !== 0) {
                data.map((item) => {
                    let netAmount = item.net_amount;
                    totalNetAmount += netAmount;
                });

                if (billDiscount.billDiscountType === 'percent') {
                    let discountAmount = (billDiscount.billDiscountValue / 100) * totalNetAmount;

                    netAmount -= discountAmount;
                } else if (billDiscount.billDiscountType === 'amount') {
                    netAmount -= billDiscount.billDiscountValue;
                }
            }

            //rounding net amount

            let adjArr = coinAdjustment(netAmount, roundValue);

            netAmount = adjArr[0];

            let roundAmount = adjArr[1];

            if (total > 1000000) {
                // Find the tcs amount
                tcsAmount = 0.01 * total;

                // Add tcs amount
                total = total + tcsAmount;

                netAmount = netAmount + tcsAmount;

                tcsAmount = getRoundedAmount(tcsAmount, 2);
            }

            // function for net amount rounding
            function coinAdjustment(amount, roundValue) {
                let decArr = [];

                decArr[0] = amount;
                decArr[1] = 0;

                if (roundValue > 0 && (amount * 100) % roundValue !== 0) {
                    decArr[0] = amount * 100;
                    decArr[0] = Math.round(decArr[0] / roundValue) * (roundValue / 100);
                    decArr[1] = parseFloat((decArr[0] - amount).toFixed(2));
                }

                return decArr;
            }

            setTotals({
                total,
                roundAmount,
                netAmount,
                cgstAmount,
                sgstAmount,
                igstAmount,
            });
            setCalcLoading(false);
        });
    }

    const showDiscountModal = () => {
        setIsModalVisible(true);
    };

    const billDiscountHandler = (values) => {
        setBillDiscount(values);
        callback(values);
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <Table
                    pagination={false}
                    scroll={{ x: true }}
                    rowKey={(record, key) => key}
                    columns={columns}
                    dataSource={data}
                    {...props}
                    size="small"
                    summary={(pageData) => {
                        //  #todo Below section will have to be optimized in future as there is enormous amount of rerendering happending for this
                        // component . But it works
                        let total = 0,
                            cgst_total = 0,
                            sgst_total = 0,
                            igst_total = 0,
                            tcs_amount = 0;

                        var cess_total = 0;

                        pageData.forEach((entry) => {
                            // Amount
                            let amount = entry.net_amount;

                            // Total
                            total += amount;

                            cgst_total += entry.cgst_amount;
                            sgst_total += entry.sgst_amount;
                            igst_total += entry.igst_amount;

                            cess_total += entry.cess_amount;
                        });

                        total = Math.round(total);

                        if (total > 1000000) {
                            tcs_amount = total * 0.01;
                            tcs_amount = getRoundedAmount(tcs_amount, 2);
                        }

                        // Add the tcs to it
                        total += tcs_amount;

                        return (
                            <>
                                <Modal title="Bill Discount" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                                    <Form {...layout} form={form} name="new-record" onFinish={billDiscountHandler} layout="vertical">
                                        <Form.Item
                                            name="billDiscountType"
                                            label="Discount"
                                            rules={[{ required: true, message: 'Please select a type' }]}
                                        >
                                            <Radio.Group>
                                                <Radio value="percent">In %</Radio>
                                                <Radio value="amount">In Rs</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Form.Item
                                            label="Value"
                                            name="billDiscountValue"
                                            dependencies={['billDiscountType']}
                                            rules={[
                                                { required: true, message: 'Please input your amount' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('billDiscountType') === 'amount') {
                                                            if (value > totals.total) {
                                                                return Promise.reject(new Error('Discount should not exceed net amount.'));
                                                            } else return Promise.resolve();
                                                        } else if (!value || getFieldValue('billDiscountType') === 'percent') {
                                                            if (value > 100) {
                                                                return Promise.reject(new Error('Discount should not exceed 100%.'));
                                                            } else return Promise.resolve();
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                {/* TCS Section */}
                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell>TCS @ 1%</Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    {isInter ? null : <Table.Summary.Cell></Table.Summary.Cell>}
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell>{tcs_amount}</Table.Summary.Cell>
                                </Table.Summary.Row>

                                {/* //Total row */}
                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell>Total</Table.Summary.Cell>

                                    {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    {data[0].cess ? null : <Table.Summary.Cell></Table.Summary.Cell>}

                                    {isInter ? (
                                        <Fragment>
                                            <Table.Summary.Cell>
                                                <Popover content={`Total IGST`}>
                                                    <Text>{calcLoading ? <Spin /> : <Rupee value={totals.igstAmount}></Rupee>}</Text>
                                                </Popover>
                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell>
                                                <Popover content={`Total CESS`}>
                                                    <Text>{calcLoading ? <Spin /> : <Rupee value={cess_total}></Rupee>}</Text>
                                                </Popover>
                                            </Table.Summary.Cell>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {/* {data[0].cess ? null : <Table.Summary.Cell></Table.Summary.Cell>} */}
                                            <Table.Summary.Cell>
                                                <Popover content={`Total SGST`}>
                                                    <Text>{calcLoading ? <Spin /> : <Rupee value={totals.sgstAmount}></Rupee>}</Text>
                                                </Popover>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <Popover content={`Total CGST`}>
                                                    <Text>{calcLoading ? <Spin /> : <Rupee value={totals.cgstAmount}></Rupee>}</Text>
                                                </Popover>
                                            </Table.Summary.Cell>

                                            {data[0].cess ? (
                                                <Table.Summary.Cell>
                                                    <Popover content={`Total CESS`}>
                                                        <Text>{calcLoading ? <Spin /> : <Rupee value={cess_total}></Rupee>}</Text>
                                                    </Popover>
                                                </Table.Summary.Cell>
                                            ) : null}
                                        </Fragment>
                                    )}

                                    {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                    <Table.Summary.Cell>
                                        <Popover content={`Total`}>
                                            <Text>{calcLoading ? <Spin /> : <Rupee value={totals.total}></Rupee>}</Text>
                                        </Popover>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>

                                {/* Discount Row */}
                                {data[0].cess ? null : (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell></Table.Summary.Cell>

                                        <Table.Summary.Cell>Discount</Table.Summary.Cell>

                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        {isInter ? null : <Table.Summary.Cell></Table.Summary.Cell>}
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <Button type="dashed" onClick={showDiscountModal}>
                                                Add discount
                                            </Button>
                                        </Table.Summary.Cell>

                                        <Table.Summary.Cell>
                                            <Popover content={`Bill Discount`}>
                                                <Text>
                                                    {calcLoading ? (
                                                        <Spin />
                                                    ) : billDiscount === 0 ? (
                                                        <Text>{billDiscount}</Text>
                                                    ) : billDiscount.billDiscountType === 'percent' ? (
                                                        <Text>{billDiscount.billDiscountValue}%</Text>
                                                    ) : (
                                                        <Rupee value={billDiscount.billDiscountValue}></Rupee>
                                                    )}
                                                </Text>
                                            </Popover>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                )}

                                {/* //Round Off row */}

                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell>Round Off</Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    {isInter ? (
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                    ) : (
                                        <Fragment>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                        </Fragment>
                                    )}

                                    {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                    <Table.Summary.Cell>
                                        <Popover content={`Round off`}>
                                            <Text>{calcLoading ? <Spin /> : <Rupee value={totals.roundAmount}></Rupee>}</Text>
                                        </Popover>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>

                                {/* //Total row */}

                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell>
                                        <Text style={{ fontSize: '16px', fontWeight: 600 }}>Grand Total</Text>
                                    </Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    <Table.Summary.Cell></Table.Summary.Cell>

                                    {isInter ? (
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                    ) : (
                                        <Fragment>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                        </Fragment>
                                    )}

                                    {/* Cart Total has to be compared with Ledger only if the head is selected, Else its pointless */}
                                    <Table.Summary.Cell>
                                        <Popover content={`Total`}>
                                            <Text style={{ fontSize: '20px', fontWeight: '600', color: '#FF0000' }}>
                                                {calcLoading ? (
                                                    <Spin />
                                                ) : (
                                                    // : billDiscount.billDiscountType === 'amount' ? (
                                                    //     <Rupee value={totals.total - billDiscount.billDiscountValue}></Rupee>
                                                    // ) : billDiscount.billDiscountType === 'percent' ? (
                                                    //     <Rupee
                                                    //         value={totals.netAmount - (totals.netAmount * billDiscount.billDiscountValue) / 100}
                                                    //     ></Rupee>
                                                    // )
                                                    <Rupee value={totals.netAmount}></Rupee>
                                                )}
                                            </Text>
                                        </Popover>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                />
            )}
        </>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}
