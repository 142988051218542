/**
 * Generic Detail accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Typography, Button, Modal, Skeleton, Tabs, Space, Row } from 'antd';

import { withRouter } from 'react-router-dom';

import moment from 'moment';

// import './employee-detail.scss';

import { ReloadOutlined } from '@ant-design/icons';

import { Employees, Designations, FinanceBranches } from '../../../../models';

import EmployeeList from './../employee-list/employee-list';

const { Title } = Typography;

const { TabPane } = Tabs;

/**
 * Generic List
 *
 * @param {*} param0
 */
function EmployeeDetail({ match, model, columns: cols, schema = { custom_actions: [] }, disableEdit = schema.disableEdit }) {

    const [record, setRecord] = useState([]);

    const [visible, setVisible] = useState(false);

    const [designation, setDesignation] = useState([]);

    const { params } = match;

    const [loading, setLoading] = useState(false);

    var designated = {}

    useEffect(() => {

        getDesignations()

        loadData();



    }, [params]);

    const loadData = () => {
        setLoading(true);

        getRecords(schema);
    };

    /**
     * Deatils of the selcted employee is loaded
     * Designation of the employee is matched with designtaions (from designation table. ID is stored in employees)
     * @param {*} schema 
     * @returns 
     */

    const getRecords = async (schema) => {

        const des = await getDesignations()
        // Get the model by queries
        return model.getRecord(match.params.id, model).then((result) => {

            if (result.designation) {

                designated = des.filter((ele) => ele.id === result.designation)
                setDesignation(designated[0])
            }

            setRecord(result);

            setLoading(false);
        });
    };


    /**
     * Get designations from designation table
     * @returns 
     */
    async function getDesignations() {
        var queries = []
        return await Designations.get(queries).then((result) => {
            console.log(result)
            return result.designations
        })
    }


    /**
     * Refresh function
     */
    function refresh() {
        loadData();
    }


    /**
     * Close Modal
     */
    function closeModal() {
        setVisible(false);
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model.name}</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                        {/* 
                        {disableEdit ? null : (
                            <Space size="middle">
                                <Button type="dashed">
                                    <Link to={`/${model.name}/${match.params.id}/e/`}>Edit</Link>
                                </Button>
                            </Space>
                        )} */}


                        {!model.ModalAddComponent ? null : (
                            <Button size={'small'} type="dashed" onClick={() => setVisible(true)}>
                                Edit
                            </Button>
                        )}

                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {/* Add Modal */}
            <Modal destroyOnClose confirmLoading={loading} title="Edit Menu" visible={visible} onCancel={() => { closeModal() }} footer={null}>
                <model.ModalAddComponent
                    match={match}
                    edit={true}

                    // menu={menu}
                    model={model}
                    // additional_queries={additional_queries}
                    formContent={record}

                    refresh={refresh}

                    callback={() => {
                        closeModal(true);
                    }}
                />
            </Modal>


            {loading ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    <div className="card">

                        <div gutter={0} className="detail-wrapper">
                            {model.name === 'employees' ? <>
                                <div className="detail-element">
                                    <span>Name</span>
                                    <Row span={6}>
                                        <h3>{record.fname + ' ' + record.lname} </h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <span>Designation</span>
                                    <Row span={6}>
                                        <h3>{designation && designation.designation ? designation.designation : null}</h3>
                                    </Row>
                                </div>
                            </> : <>
                                <div className="detail-element">
                                    <span>Name</span>
                                    <Row span={6}>
                                        <h3>{record.name} </h3>
                                    </Row>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    <Tabs>
                        <TabPane tab={`Sub ${model.name}`} key="0">
                            {model.name === 'employees' ? <>
                                <EmployeeList
                                    model={Employees}
                                    // history={history}
                                    additional_queries={[{
                                        field: 'header_id',
                                        value: params.id,
                                    }]}
                                />
                            </> : <>
                                <EmployeeList
                                    model={FinanceBranches}
                                    // history={history}
                                    additional_queries={[{
                                        field: 'bank_id',
                                        value: params.id,
                                    }]}
                                />
                            </>}
                        </TabPane>
                    </Tabs>
                </>
            )}
        </section>
    );
}

export default withRouter(EmployeeDetail);
