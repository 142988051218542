import React, { useState, useEffect, useContext } from 'react';
import {
    Table,
    Dropdown,
    Menu,
    Typography,
    Button,
    Space,
    Tabs,
    Tag,
    Progress,
    Statistic,
    Row,
    Col,
    DatePicker,
    message,
    Select,
    Checkbox,
    Badge,
} from 'antd';

import FirebaseUtils from '../../../../utils/firebase.utils';

import Rupee from '../../../../components/rupee/rupee';

import { OrderedListOutlined, DownOutlined, CheckCircleOutlined } from '@ant-design/icons';

import RangePicker from './../../resources/rangepicker/rangepicker';

import { GlobalContext } from '../../../../Store';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';
import { Link } from 'react-router-dom';
import moment, { months } from 'moment-timezone';

import { ExportXLS } from '../finance-tracker/ExportXLS';

import { BillDetails, Bills, Bookings, Cart, CustomerEntries, Departments, Employees, Requests } from '../../../../models/';

import './monthwise-retail.scss';

import ApiUtils from '../../../../utils/api.utils';

const { Title, Text } = Typography;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
// const { RangePicker } = DatePicker;

const MonthWiseRetail = () => {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        getDepartments();
    }, []);

    /**
     * Get all the departments of the
     */
    async function getDepartments() {
        var queries = [
            {
                field: 'description',
                value: 'Department Handling Sales',
            },
        ];

        const { departments = [] } = await Departments.get(queries);

        setDepartments(departments);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.id && record.reqid ? (
                            <Link
                                style={{
                                    fontSize: '12px',
                                    textTransform: 'capitalize',
                                }}
                                to={`/requests/${record.reqid}`}
                            >
                                {record.customer.customerName}
                            </Link>
                        ) : (
                            <div>
                                <div>{record.customer_name}</div>
                            </div>
                        )}
                    </Space>
                );
            },
            width: 200,
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicle',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '12px' }}>{record.attributes && record.attributes.vinNo}</div>
                        <div style={{ fontSize: '10px' }}>{record.attributes && record.attributes.variant}</div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>{record && record.color ? record.color : null}</div>
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Booking No',
            render: (text, record) => {
                if (record.item && (record.item.bookingNo || record.item['Booking No']))
                    return <div style={{ fontSize: '12px' }}>{(record.item && record.item['Booking No']) || record.item.bookingNo}</div>;
            },
            width: 200,
        },

        {
            title: 'Dealer Code',
            render: (text, record) => {
                return (
                    <div style={{ fontSize: '12px' }}>
                        {record.dealerCode}
                    </div>
                );
            },
            width: 200,
        },

        {
            title: 'Booking Date',
            render: (text, record) => {
                if ((record.booking && (record.booking['Booking Date']) || record.item && record.item['Booking Date']))
                    return <div style={{ fontSize: '12px' }}>{(record.booking && record.booking['Booking Date']) || record.item['Booking Date']}</div>;
            },
            width: 200,
        },

        {
            title: 'Invoice Date',
            render: (text, record) => {
                return (
                    <div style={{ fontSize: '12px' }}>
                        {(record.item && record.item['Invoice Date']) || (record.item && moment(record.item.invoice_date_millisecond).format('DD/MM/YY')) || moment(record.invoice_date).format('DD/MM/YY')}
                    </div>
                );
            },
            width: 200,
        },

        // {
        //     title: 'Retail Date',
        //     // dataIndex: 'billing.status',
        //     render: (text, record) => {
        //         return <div style={{ fontSize: '12px' }}>{record.billing && record.billing.invoiced_at}</div>;
        //     },
        // },

        {
            title: 'Ex Showroom Price',
            dataIndex: 'exShowroomPrice',
            render: (text, record) => {
                if (record && record.exShowroomPrice) {
                    var exShowroomPrice = record.exShowroomPrice.toFixed(2);
                    // var vcr = vc.toFixed(2);
                    return <div>{exShowroomPrice}</div>;
                }
            },
            width: 200,
        },

        {
            title: 'Vehicle Price',
            dataIndex: 'vehicleCost',
            render: (text, record) => {
                if (record && record.cartTotal) {
                    var vehiclePrice = record.cartTotal.toFixed(2);
                    // var vcr = vc.toFixed(2);
                    return <div>{vehiclePrice}</div>;
                }
            },
            width: 150,
        },
        {
            title: 'Amount Received',
            dataIndex: 'DP',
            render: (text, record) => {
                let dp = record.receiptTotal.toFixed(2);
                if (record && record.receiptTotal)
                    return <Badge status={record.accounts && record.accounts.ac_code && record.accounts.ac_code.value ? 'success' : 'error'} text={dp} />;
            },
            width: 150,
        },

        {
            title: 'Receivable Towards Vehicle',
            dataIndex: 'receivableTowardsVehicle',
            render: (text, record) => {
                let receivableTowardsVehicle;
                if (record && record.exShowroomPrice) {
                    receivableTowardsVehicle = record.exShowroomPrice - record.receiptTotal;
                    let calculatedBalance = receivableTowardsVehicle.toFixed(2);
                    return <div>{calculatedBalance}</div>;
                }
            },
            width: 200,
        },

        {
            title: 'Total Receivable',
            dataIndex: 'balance',
            render: (text, record) => {
                let balance
                if (record && record.cartTotal) {
                    balance = record.cartTotal - record.receiptTotal;
                    let balanceR = balance.toFixed(2);
                    return <div>{balanceR}</div>;
                }
            },
            width: 150,
        },

        {
            title: 'Pending for Clearing',
            dataIndex: 'DP',
            render: (text, record) => {
                let dp = 0
                if (record && record.pending)
                    dp = record.pending;

                let dpr = parseFloat(dp).toFixed(2);

                return <div>{dpr}</div>;
            },
            width: 200,
        },

        {
            title: 'Delivery Status',
            render: (text, record) => {
                if (record && record.delivery)
                    return <Tag>{record.delivery && record.delivery.status}</Tag>;
            },
            width: 150,
        },
    ];

    return (
        <div>
            <div className="report listing">
                <div className="page-header">
                    <div className="left">
                        <Title level={3}>Monthwise Retail</Title>
                    </div>
                </div>
            </div>

            {departments.length && <Allocation columns={columns} isBilling={true} hideDate={true} departments={departments} />}
        </div>
    );
};

function Allocation({ columns, isBilling, hideDate, departments }) {
    const [startdate, setStartdate] = useState(moment().startOf('month'));

    const [enddate, setEnddate] = useState(moment());

    const [range, setRange] = useState([startdate, enddate]);

    const [hackValue, setHackValue] = useState();

    const [billType, setBillType] = useState('Surge Report');

    const [dms, setDms] = useState(false);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState({ requests: [] });

    const [finResult, setFinResult] = useState();

    const [location, setLocation] = useState(user.locations[0]);

    const [approval, setApproval] = useState(false);

    function updateTime(dt) {
        setResults([]);
        setRange(dt);
        if (dms) {
            getDmsReport(location, dt);
        } else {
            getSurgeReport(location, dt);
        }
    }

    var dmsqueries = (range) => {
        return [
            {
                field: 'confirm_date',
                operator: '>=',
                // value: 1580236200000,
                value: range[0].startOf('day').valueOf(),
            },

            {
                field: 'confirm_date',
                operator: '<=',
                value: range[1].endOf('day').valueOf(),
                // value: 1590777000000,
            },
        ];
    };

    var surgequeries = (range) => {
        return [
            {
                field: 'invoice_date_millisecond',
                operator: '>=',
                // value: 1580236200000,
                value: range[0].startOf('day').valueOf(),
            },

            {
                field: 'invoice_date_millisecond',
                operator: '<=',
                value: range[1].endOf('day').valueOf(),
                // value: 1590777000000,
            },
            {
                field: 'type',
                value: 'basic',
            },
            {
                field: 'cancellation_status',
                value: false,
            },
        ];
    };

    useEffect(() => {
        if (dms) {
            if (location && range) {
                getDmsReport(location, range);
            }
        } else {
            getSurgeReport(location, range);
        }
    }, [billType, location]);

    function refresh() {
        if (dms) {
            getDmsReport(location, range);
        } else {
            getSurgeReport(location, range);
        }
    }

    /**
     *
     *
     * @param {*} location
     * @param {*} initialQueries
     * @param {*} isBilling
     * @returns
     */

    function getAllDmsData(location, initialQueries) {
        let arr = [];

        // Ensure locations is an array
        if (!Array.isArray(location)) {
            location = [location];
        }

        // Process each location
        let getLocations = location.map((location) => {
            return new Promise((resolve, reject) => {
                FirebaseUtils.getRecords(location, initialQueries, 'sales').then((result) => {
                    Promise.all(
                        result.sales.map((item) => {
                            return FirebaseUtils.getRecords(
                                location,
                                [
                                    { field: 'bookingNo', operator: '==', value: item['Booking No'] },
                                    { field: 'status', operator: '!=', value: 'removed' }
                                ],
                                'requests'
                            ).then((res) => {
                                if (res.requests[0]) {
                                    arr.push({
                                        ...res.requests[0],
                                        reqid: res.requests[0].id,
                                        item,
                                        refresh: refresh,
                                    });
                                }
                                return arr;
                            });
                        })
                    ).then(resolve).catch(reject);
                }).catch(reject);
            });
        });

        // Combine results from all locations
        return new Promise((resolve, reject) => {
            Promise.all(getLocations).then(() => {
                resolve(arr);
            }).catch(reject);
        });
    }

    function getAllSurgeData(location, initialQueries) {
        let arr = [];
        let resArr = [];

        // Ensure locations is an array
        if (!Array.isArray(location)) {
            location = [location];
        }

        // To iterate each location to get data  
        let getLocations = location.map((loc) => {
            return new Promise((resolve, reject) => {
                FirebaseUtils.getRecords(loc, initialQueries, 'bills').then((result) => {
                    if (result.bills.length) {
                        Promise.all(
                            result.bills.map(async (item) => {
                                if (item.attached_with != 'independent') {
                                    return FirebaseUtils.getRecords(
                                        loc,
                                        [
                                            { field: 'bookingNo', operator: '==', value: item.bookingNo },
                                            { field: 'status', operator: '!=', value: 'removed' }
                                        ],
                                        'requests'
                                    ).then((res) => {
                                        if (res.requests[0]) {
                                            arr.push({
                                                ...res.requests[0],
                                                reqid: res.requests[0].id,
                                                invoice_date_millisecond: item.invoice_date_millisecond,
                                                item,
                                                refresh: refresh,
                                            });
                                        }
                                    });
                                } else {
                                    arr.push({
                                        ...item,
                                        refresh: refresh,
                                    });
                                }
                            })
                        ).then(resolve).catch(reject);
                    } else {
                        resolve();
                    }
                }).catch(reject);
            });
        });

        // Combine results from all locations
        return new Promise((resolve, reject) => {
            Promise.all(getLocations).then(() => {
                if (arr.length) {
                    Promise.all(
                        arr.map((item) => {
                            if (item.bookingNo) {
                                return Bookings.get([{ field: 'Booking No', operator: '==', value: item.bookingNo }]).then((booking) => {
                                    resArr.push({
                                        ...item,
                                        booking: booking.bookings[0],
                                        refresh: refresh,
                                    });
                                });
                            } else {
                                resArr.push({ ...item });
                            }
                        })
                    ).then(() => resolve(resArr)).catch(reject);
                } else {
                    resolve([]);
                }
            }).catch(reject);
        });
    }


    /**
     * Get data of dms
     */
    function getDmsReport(location, range) {
        setLoading(true);

        let initialQueries = [];

        initialQueries = dmsqueries(range);

        // As a first stage , lets load all the allocated vehicles that are to be delivered . These are the cases for which we have to collect funds
        // At our best attempt we have to reduce the time for delivery for these cases

        // We neednt get the data for vehicle overview , It includes unallocated vehicles too ,
        // Instead we should load all the ongoing requests
        getAllDmsData(location, initialQueries).then((result) => getData(result));
    }

    /**
     * Get data of surge
     */
    function getSurgeReport(location, range) {
        setLoading(true);

        let initialQueries = [];

        initialQueries = surgequeries(range);

        // As a first stage , lets load all the allocated vehicles that are to be delivered . These are the cases for which we have to collect funds
        // At our best attempt we have to reduce the time for delivery for these cases

        // We neednt get the data for vehicle overview , It includes unallocated vehicles too ,
        // Instead we should load all the ongoing requests
        getAllSurgeData(location, initialQueries).then((result) => getData(result));
    }

    function getData(result) {
        setResults({ requests: result });
        if (result && result.length) {
            // Get all accounts with head
            var accounts = result.filter((account) => account.accounts && account.accounts.ac_code).map((account) => account.accounts.ac_code.value);
        }

        function getTotal(response) {
            return Promise.all(
                result.map(async (request) => {
                    // Checking if cs accounts data to be shown
                    return Requests.disableCsAccounts(request).then(async (result) => {
                        // if cs accounts and isBilling
                        if (result.enable) {
                            return getTotalValues(request, response);
                        }
                        else {
                            return getTotalValues(request);
                        }
                    })
                })
            ).then((result) => {
                // Penetration count variables

                setResults({ requests: result });

                let exportData =
                    result &&
                    result
                        .slice(0)
                        // .reverse()
                        .map((item) => {
                            if (item && item.allocation) {
                                if (isBilling) {
                                    // Build a array to export data to csv file
                                    let content = {
                                        // refresh: refresh,

                                        Vehicle: item.allocation.vinNo,
                                        Model: item.model,
                                        Variant: item.variant,
                                        Color: item.color,
                                        'Dealer Code':item.dealerCode,
                                        Customer: item.id ? item.customer.customerName : item['Cust Name'],
                                        Phone: item.customer && item.customer.phone,

                                        'Allocation date': item.allocation.allocated_at || item.allocation.created_at,

                                        'Retail date': item.billing.invoiced_at,

                                        KEC: item.KEC,

                                        Type: item.finance.type,

                                        Bank: item.finance.bank,

                                        'Ex Showroom Price': item.exShowroomPrice,
                                        'Vehicle Price': item.cartTotal,
                                        'Amount Received': item.receiptTotal,
                                        'Receivable Towards Vehicle': item.receivableTowardsVehicle,
                                        'Total Receivable': item.balance,

                                        Finance: item.finance && item.finance.status,
                                        Retail: item.billing && item.billing.status,
                                        Accessories: item.accessories && item.accessories.status,
                                        Insurance: item.insurance && item.insurance.status,
                                        RTO: item.rto && item.rto.status,
                                    };

                                    if (item.delivery && item.delivery.status) {
                                        if (item.delivery.schedule === 'Delivery Scheduled') {
                                            content = {
                                                ...content,
                                                Delivery:
                                                    'Scheduled_At :' + moment(item.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A'),
                                            };
                                        } else {
                                            content = {
                                                ...content,
                                                Delivery: item.delivery.status,
                                            };
                                        }
                                    } else {
                                        content = {
                                            ...content,
                                            Delivery: 'Pending',
                                        };
                                    }

                                    return content;
                                } else {
                                    // Build a array to export data to csv file
                                    let content = {
                                        // refresh: refresh,

                                        Vehicle: item.allocation.vinNo,
                                        Model: item.model,
                                        Variant: item.variant,
                                        Color: item.color,
                                        'Dealer Code':item.dealerCode,

                                        Customer: item.id ? item.customer.customerName : item['Cust Name'],
                                        Phone: item.customer && item.customer.phone,

                                        'Allocation date': item.allocation.allocated_at || item.allocation.created_at,

                                        'Retail date': item.billing.invoiced_at,

                                        KEC: item.KEC,

                                        TeamLeader: item.TeamLeader,

                                        Type: item.finance && item.finance.type,
                                        Bank: item.finance && item.finance.bank,

                                        'Ex Showroom Price': item.exShowroomPrice,
                                        'Vehicle Price': item.cartTotal,
                                        'Amount Received': item.receiptTotal,
                                        'Receivable Towards Vehicle': item.receivableTowardsVehicle,
                                        'Total Receivable': item.balance,

                                        Finance: item.finance && item.finance.status,
                                        Accessories: item.accessories && item.accessories.status,
                                    };

                                    if (isBilling) {
                                        if (item.delivery && item.delivery.status) {
                                            if (item.delivery.schedule === 'Delivery Scheduled') {
                                                content = {
                                                    ...content,
                                                    Delivery:
                                                        'Scheduled_At :' +
                                                        moment(item.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A'),
                                                };
                                            } else {
                                                content = {
                                                    ...content,
                                                    Delivery: item.delivery.status,
                                                };
                                            }
                                        } else {
                                            content = {
                                                ...content,
                                                Delivery: 'Pending',
                                            };
                                        }
                                    }

                                    return content;
                                }
                            } else {
                                let content = {
                                    // refresh: refresh,

                                    Vehicle: item.attributes.vinNo,
                                    Model: item.attributes.model,
                                    Variant: item.attributes.variant,
                                    Color: item.color,
                                    Customer: item.customer_name,
                                    Phone: item.customer_phone,

                                    'Allocation date': item.created_at,

                                    'Retail date': item.invoice_date,

                                    // KEC: item.kec,

                                    Type: item.type,

                                    Payable: item.total_payable,

                                    Paid: item.total,

                                    Balance: item.balance,

                                    // Finance: item.finance && item.finance.status,
                                    // Retail: item.billing && item.billing.status,
                                    // Accessories: item.accessories && item.accessories.status,
                                    // Insurance: item.insurance && item.insurance.status,
                                    // RTO: item.rto && item.rto.status,
                                };
                                return content
                            }
                        });

                //

                setFinResult(exportData);

                setLoading(false);
            });
        }

        if (result && result.length) {
            if (isBilling) {
                let cs_accounts = false;
                Promise.all(
                    result.map(async (res) => {
                        // Checking if cs accounts data to be shown
                        return Requests.disableCsAccounts(res).then(async (result) => {
                            // if cs accounts and isBilling
                            if (result.enable) {
                                cs_accounts = true
                            }
                        })
                    })
                ).then((res) => {
                    // Department
                    if (cs_accounts && accounts.length) {

                        if (Array.isArray(location) && location.length > 0) {
                            // Handling the case where location is an array (even with one element)
                            let department = departments.find((dept) =>
                                location.some(loc => loc.toLowerCase() === dept.location.toLowerCase())
                            );

                            if (department) {
                                ApiUtils.getBulkLedgerDetails(department, accounts).then((response) => {
                                    console.log(response);
                                    getTotal(response);
                                });
                            } else {
                                getTotal();
                            }
                        } else {
                            getTotal();
                        }
                    } else {
                        getTotal();
                    }
                })
            } else {
                getTotal();
            }
        } else {
            setLoading(false);
        }
    }

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setLocation(e.key);

        if (dms) {
            getDmsReport(e.key, range);
        } else {
            getSurgeReport(e.key, range);
        }
    }

    function handleLocations(value = []) {
        setLocation(value);
        if (dms) {
            getDmsReport(value, range);
        } else {
            getSurgeReport(value, range);
        }
    }

    function updateApproval(e) {
        setApproval(e.target.checked);
    }

    let filtered = result.requests;

    if (approval) {
        filtered = result.requests.filter((record) => {
            return record.actual_billing.approvalRequired;
        });
    } else {
        filtered = result.requests;
    }

    var billing = ['Surge Report', 'DMS Report'];

    const allocationMenus = billing.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const allocationMenu = <Menu onClick={handleClick}>{allocationMenus}</Menu>;

    function handleClick(e) {
        setBillType(e.key);

        console.log(e.key);

        if (e.key === 'DMS Report') {
            setDms(true);
        } else {
            setDms(false);
        }
    }

    return (
        <div className="finance-tracker listing">
            <div className="header">
                <div className="header-actions">
                    {/* For Pending cases , we allow filter for special approval cases */}
                    {hideDate ? (
                        <>
                            <Checkbox value={approval} onChange={updateApproval}>
                                Retailed with Approval
                            </Checkbox>
                        </>
                    ) : null}

                    <Dropdown overlay={allocationMenu}>
                        <Button size={'small'}>
                            {billType} <DownOutlined />
                        </Button>
                    </Dropdown>

                    <Button
                        onClick={() => {
                            refresh();
                            // setRange(moment.tz('Asia/Calcutta').endOf('day'));
                            // getRequests(location);
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>

                    {/* <Dropdown overlay={menu}>
                        <Button size={'small'}>
                            {location} <DownOutlined />
                        </Button>
                    </Dropdown> */}

                    <Select
                        mode="multiple"
                        allowClear
                        size="small"
                        style={{ width: location.length === 0 ? "150px" : "auto", marginRight: "8px" }}
                        value={location}
                        onChange={handleLocations}
                        placeholder="Select Locations"
                    >
                        {user.locations.map((location, index) => (
                            <Option key={index} value={location}>
                                {location}
                            </Option>
                        ))}
                    </Select>


                    {/* <RangePicker
                        value={hackValue || range}
                        // disabledDate={disabledDate}
                        // onOpenChange={onOpenChange}
                        // onCalendarChange={onCalendarChange}
                        inputReadOnly
                        format={dateFormat}
                        //value={range}
                        onChange={updateTime}
                        allowClear={false}
                    /> */}
                    <RangePicker
                        allowClear={false}
                        inputReadOnly
                        format={'DD/MM/YYYY'}
                        value={range}
                        onChange={(time) => {
                            updateTime(time, range);
                        }}
                        ranges={{
                            Today: [moment(), moment()],

                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                            'This Week': [moment().startOf('week'), moment().endOf('week')],

                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                            'This Month': [moment().startOf('month'), moment().endOf('month')],

                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        }}
                    />

                    {/*table data export to csc component*/}
                    {finResult && <ExportXLS csvData={finResult} />}
                </div>
            </div>

            {loading ? <PlaceHolder type="listing" /> : <>{result && <TableView data={filtered} columns={columns} />}</>}
        </div>
    );
}

function TableView({ data, callback, columns }) {
    return (
        <Table
            // width="100%"
            size="small"
            scroll={{ x: true }}
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            // onChange={handleTableChange}
            pagination={false}
            summary={(pageData) => {
                let totalVC = 0;
                let totalDP = 0;
                let totalBalance = 0;
                let totalPending = 0;

                pageData.forEach((entry) => {
                    totalVC += parseFloat(entry['cartTotal']);
                    totalDP += parseFloat(entry['receiptTotal']);
                    totalBalance += parseFloat(entry['balance']);
                    totalPending += parseFloat(entry['pending']);
                });

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Title level={5}>Total</Title>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={totalVC}></Rupee>
                                </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={totalDP}></Rupee>
                                </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={totalBalance}></Rupee>
                                </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                                <Rupee value={totalPending}></Rupee>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        ></Table>
    );
}

function getTotalValues(request, response) {
    var cartTotal = loadCartTotal(request);
    var receiptTotal;

    // Checking if cs accounts data to be shown
    if (response) {

        receiptTotal = loadReceiptTotal(request, response);
    }
    else {

        receiptTotal = Requests.loadReceiptTotal(request)
    }

    // for getting kec by consultant_id
    var kec = loadKec(request.consultant_id);

    // for getting teamLeader by teamleader_id
    var teamLeader = loadTeamLeader(request.teamleader_id);

    return Promise.all([cartTotal, receiptTotal, kec, teamLeader]).then((result) => {

        return {
            ...request,


            KEC: result[2].kecName,
            TeamLeader: result[3].teamLeader,
            cartTotal: result[0].cartTotal,
            exShowroomPrice: result[0].exShowroomPrice,
            receivableTowardsVehicle: result[0].exShowroomPrice - result[1].paidtotal,
            receiptTotal: result[1].paidtotal,
            pending: result[1].pendingcleartotal,
            balance: result[0].cartTotal - result[1].paidtotal,
        };
    });
}

function loadCartTotal(request) {
    // var tableData = {};
    var arr = [];

    // For each request we have to find the cart total and receipt total to be used
    // in the table

    return new Promise((resolve, reject) => {
        if (request.bookingNo) {
            var queries = [
                {
                    field: 'bookingNo',
                    value: request.bookingNo,
                },
                {
                    field: 'dealerCode',
                    value: request.dealerCode,
                },
            ];

            return Cart.get(queries).then(
                // We have the cart items as an array,
                // We have to add them and assign it to array object

                (result) => {
                    // let total = (cartItems).reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0)
                    // let cartTotal = (cartItems).reduce((a, b) => a + (parseInt(b['price']) * (b['quantity']) || 1), 0)
                    let cartTotal = 0;

                    let exShowroomPrice = 0;

                    result = result.cart
                        .filter((items) => {
                            return items.status !== 'removed' && items.status !== 'rejected';
                        })
                    // .forEach((items) => {
                    // Sometimes, there are two records created for extended warranty in cart
                    // Till problem is resolved, to mak ethe amounts similar to settlement in request details, we are taking the same approach used in payable entries
                    // Group all the records of cart with type
                    // And only take the first elemnt in the extended warranty group
                    let categories = groupBy(result, 'type');
                    Object.keys(categories).forEach((element) => {
                        if (element === 'Extended Warranty') {
                            arr.push(categories[element][0])
                        } else {
                            categories[element].map((item) => {
                                arr.push(item)
                            })
                        }
                    })
                    // if (items.type !== 'Accessories' && items.type !== 'VAS') {
                    //     let repeat = arr.filter((i) => i.type === items.type);
                    //     if (!repeat.length) {
                    //         arr.push(items);
                    //     }
                    // } else {
                    // arr.push(items);
                    //  }
                    // });

                    return FirebaseUtils.getBillingDetails(request).then((billItems) => {
                        // Older approach, might be needed once the issue with saving extended warranty is sorted

                        // if (billItems && billItems[0]) {
                        //     billItems
                        //         .filter((items) => {
                        //             return items.status !== 'removed' && items.status !== 'rejected';
                        //         })
                        //         .forEach((items) => {
                        //             arr.push(items);
                        //         });
                        // }
                        // Sometimes, there are two records created for extended warranty in bill-details
                        // Till problem is resolved, to mak ethe amounts similar to settlement in request details, we are taking the same approach used in payable entries
                        // Group all the records of bill-details with type
                        // And only take the first elemnt in the extended warranty group

                        if (billItems.length) {
                            billItems = billItems.filter((items) => {
                                return items.status !== 'removed' && items.status !== 'rejected'
                            })
                            let categories = groupBy(billItems, 'type');
                            Object.keys(categories).forEach((element) => {
                                if (element === 'Extended Warranty') {
                                    arr.push(categories[element][0])
                                } else {
                                    categories[element].map((item) => {
                                        arr.push(item)
                                    })
                                }
                            })
                        }
                        var query = [
                            {
                                field: 'bookingNo',
                                value: request.bookingNo,
                            },
                            {
                                field: 'dealerCode',
                                value: request.dealerCode,
                            },
                        ];

                        return Bills.get(query).then((res) => {
                            if (res.bills && res.bills[0]) {
                                res.bills
                                    .filter((items) => {
                                        return items.cancellation_status !== true;
                                    })
                                    .forEach((items) => {
                                        // getting the exhowroom price
                                        if (items.type == "basic") {
                                            if (items.tcs_amount) {
                                                exShowroomPrice = items.net_amount - items.tcs_amount;
                                            } else {
                                                exShowroomPrice = items.net_amount;
                                            }
                                        }
                                        arr.push(items);
                                    });
                            }

                            arr.forEach((items) => {
                                if (items.net_amount) {
                                    cartTotal = cartTotal + items.net_amount;
                                } else if (!items.net_amount && items['price']) {
                                    items.subItems &&
                                        items.subItems.map((subitem) => {
                                            if (subitem.status !== 'removed') {
                                                cartTotal = cartTotal + subitem.price;
                                            }
                                        });
                                    if (!items.subItems) {
                                        cartTotal = cartTotal + items['price'] * (items['quantity'] || 1);
                                    }
                                }
                            });

                            let total = {
                                cartTotal: cartTotal,
                                exShowroomPrice: exShowroomPrice
                            }

                            resolve(total);
                        });
                    });
                }
            );
        } else {
            let total = {
                cartTotal: 0,
                exShowroomPrice: 0
            }
            resolve(total);
        }
    });
}

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
};


/**
 * Load receipt total from accounts or dms receipts
 *
 * @param {*} request
 * @param {*} response
 * @returns
 */
function loadReceiptTotal(request, response) {
    var obj = {};

    var queries = [
        {
            field: 'Appointment No',
            value: request.bookingNo,
        },
    ];

    if (request.accounts && request.accounts.ac_code && request.accounts.ac_code.value && response) {
        return new Promise((resolve, reject) => {
            response.forEach((res) => {
                if (res.acount_pointer == request.accounts.ac_code.value) {
                    obj = res;
                }
            });

            var ledgerReceipts = obj.ledgers.filter((entry) => entry.Trn === -1);

            var paidRecipt = ledgerReceipts;

            var recivedtotal = paidRecipt.reduce((a, b) => a + parseInt(b['CrAmt']), 0);

            var clearedRecipt = ledgerReceipts.filter((entry) => entry.OpAcPDCStatus === 'R' || entry.OpAcPDCStatus === 'N');

            var clearedTotal = clearedRecipt.reduce((a, b) => a + parseInt(b['CrAmt']), 0);

            var openingtotal = -obj.opening_balance;

            var totalBalance = recivedtotal + openingtotal;

            var total = {
                paidtotal: totalBalance,
                pendingcleartotal: totalBalance - (clearedTotal + openingtotal),
            };

            resolve(total);
        });
    } else {
        return new Promise((resolve, reject) => {
            if (request.bookingNo) {
                return FirebaseUtils.getReceipts(request.city, queries).then((content) => {
                    // We have the Receipt items as an array,
                    // We have to add them and assign it to array object
                    let receipts = content.receipts;

                    let totalBalance = receipts.reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0);

                    var total = {
                        paidtotal: totalBalance,
                        pendingcleartotal: 0,
                    };
                    resolve(total);
                });
            } else {
                resolve({
                    paidtotal: 0,
                    pendingcleartotal: 0,
                });
            }
        });
    }
}


//for getting kec by consultant_id
async function loadKec(employee_id) {
    if (employee_id) {
        return new Promise((resolve) => {
            return Employees.getRecord(employee_id).then(
                (result) => {
                    var kec
                    if (!result.lname || result.lname.length === 0) {
                        kec = {
                            kecName: result.fname
                        }
                    }
                    else {

                        kec = {
                            kecName: result.fname + " " + result.lname,
                        }
                    }
                    resolve(kec);
                }
            );
        });
    }
    else {
        return new Promise((resolve) => {
            let kec = {
                kecName: "",
            }
            resolve(kec);
        });
    }
}
//  for getting TeamLeader teamleader_id
async function loadTeamLeader(employee_id) {
    if (employee_id) {
        return new Promise((resolve) => {
            return Employees.getRecord(employee_id).then(
                (result) => {

                    let tl = {
                        teamLeader: result.fname + " " + result.lname,
                    }
                    resolve(tl);
                }
            );
        });
    }
    else {
        return new Promise((resolve) => {
            let tl = {
                teamLeader: "",
            }
            resolve(tl);
        });
    }
}

export default MonthWiseRetail;
