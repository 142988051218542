import Base from './base';

import EmployeeList from '../modules/employees/components/employee-list/employee-list';

import EmployeeAdd from '../modules/employees/components/employee-add/employee-add';

import EmployeeDetail from '../modules/employees/components/employee-detail/employee-detail';

class FinanceBank extends Base {
    constructor() {
        super();

        this.fields = [];
    }

    get getEndpoint() {
        return 'finance_banks';
    }

    get path() {
        return `finance_banks`;
    }

    get getName() {
        return `finance_banks`;
    }
    get columns() {
        return [
            {
                caption: 'Name',
                field: 'name',
                key: 'name',
            },
        ];
    }

    ListComponent = (props) => <EmployeeList model={this} {...props} />;

    ModalAddComponent = (props) => <EmployeeAdd model={this} {...props} />;

    EditComponent = (props) => <EmployeeAdd {...props} edit={true} model={this} />;

    DetailComponent = () => <EmployeeDetail model={this} />;
}

export default FinanceBank;
